.smartmoney-investment {
  &__title,
  &__tabs {
    position: relative;
    z-index: 5;
  }
  &__title {
    text-transform: uppercase;
    margin-bottom: s(48.5);
    font-weight: 700;
    font-size: s(60);
    line-height: 110%;
    color: #898a8e;
    & .underline {
      border-bottom: s(3) solid var(--white);
      color: var(--white);
    }
  }
  &__tab {
    @include flex-50-50();
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    min-height: s(122);
    border-radius: s(10);
    font-size: s(20);
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.04em;
    background-image: url('../img/texture.svg');
    @include transition(background-color, var(--red));
    & span {
      width: 100%;
      max-width: s(285);
    }
    &:hover,
    &:active,
    &.active {
      background-color: var(--grey);
    }
    &s {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(s(304), 1fr));
      gap: s(40);
    }
    &-container {
      position: relative;
      overflow: hidden;
      grid-column: 1/6;
      min-height: s(301);
      padding: s(41);
      border-radius: s(10);
      background-color: var(--grey);
      background-size: cover;
      &.hidden {
        display: none;
      }
      & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100rem;
        object-fit: unset;
      }
    }
    &-box {
      display: none;
      gap: s(40);
      &.active {
        display: flex;
      }
    }
  }
  &__dropdown {
    display: none;
    height: 100%;
    padding: s(39) s(40) s(40) s(75);
    will-change: height;
    & > p {
      position: relative;
      font-family: 'GraphikLCG';
      width: 100%;
      max-width: s(393);
      font-size: s(22);
      font-weight: 400;
      line-height: 130%;
      & > svg {
        position: absolute;
        top: s(4);
        left: s(-38);
        @include block(s(16));
      }
      &:not(:last-child) {
        margin-bottom: s(25);
      }
    }
    &,
    &-btn {
      position: relative;
      width: 100%;
      border-radius: s(10);
      border: s(1) solid var(--white);
    }
    &--all {
      position: absolute;
      @include flex-50-50();
      visibility: visible;
      opacity: 1;
      bottom: s(39);
      right: s(36);
      @include block(s(53));
      border: s(0.5) solid var(--white);
      border-radius: 100%;
      @include transition((opacity, visibility));
      & svg {
        @include block(s(25));
        transform: rotate(90deg);
      }
      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
    }
    &-box {
      display: flex;
      flex-direction: column;
      flex-basis: calc(100% - s(40) * 2);
    }
    &-btn {
      min-height: s(127);
      margin-bottom: s(10);
      &.active {
        & i svg {
          transform: rotate(0deg);
        }
      }
      & strong {
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        max-width: s(380);
        font-size: s(18);
        font-weight: 700;
        line-height: 160%;
        letter-spacing: 0.04em;
      }
      & i {
        @include flex-50-50();
        @include block(s(40));
        position: absolute;
        border-radius: 100%;
        border: s(0.5) solid var(--white);
        bottom: s(21);
        right: s(18);
        & svg {
          @include block(s(11));
          @include transition(transform, rotate(95deg));
        }
      }
    }
  }
  @include mobile {
    &__title {
      margin-bottom: s-md(28);
      font-size: s-md(25);
    }
    &__tab {
      text-align: left;
      min-height: s-md(98);
      font-size: s-md(18);
      padding: 3.5rem 3.2rem 4.2rem;
      & span {
        max-width: initial;
      }
      &s {
        grid-template-columns: 100%;
        gap: s-md(12);
        & > li {
          @include transition(all);
        }
      }
      &-container {
        grid-column: initial;
        padding: s-md(10) s-md(10) s-md(2);
        border-radius: s-md(10);
      }
      &-box {
        flex-direction: column;
        gap: s-md(3.5);
      }
    }
    &__dropdown {
      padding: s-md(19) s-md(18) s-md(18.5) s-md(53);
      &.active {
        margin-bottom: s-md(100);
      }
      & > p {
        max-width: initial;
        font-size: s-md(18);
        line-height: 128%;
        &:not(:last-child) {
          margin-bottom: 2.3rem;
        }
        & > svg {
          top: s-md(5);
          left: s-md(-37);
          @include block(3rem);
        }
      }
      &-box {
        &:last-child {
          & .smartmoney-investment__dropdown {
            margin-bottom: s-md(8.5);
          }
        }
      }
      &-btn {
        display: flex;
        align-items: flex-start;
        min-height: s-md(93);
        margin-bottom: s-md(8.5);
        padding: s-md(19) s-md(18) s-md(22);
        & strong {
          text-align: left;
          float: left;
          max-width: s-md(230);
          font-size: s-md(16);
          &.more-three-lines {
            padding-bottom: s-md(22);
          }
        }
        & i {
          bottom: s-md(20);
          right: s-md(17);
          @include block(s-md(53));
          & svg {
            width: 3.1rem;
            height: 3.1rem;
            transform: rotate(90deg);
          }
        }
      }
      &--all {
        position: static;
        right: 0;
        bottom: initial;
        margin: s-md(5) s-md(18) s-md(8) auto;
        @include block(s-md(53));
        & svg {
          width: 4.5rem;
          height: 4.5rem;
          margin-top: s-md(3);
        }
        &.active {
          position: absolute;
          margin: s-md(26) s-md(27) 0 0;
        }
      }
    }
  }
}
