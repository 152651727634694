.mass-media {
  padding-top: s(188);
  &__title {
    font-size: s(60);
    & .underline::after {
      bottom: s(3);
    }
    &-box {
      display: flex;
      justify-content: space-between;
      padding-bottom: s(56);
    }
  }
  &__slider {
    &-btn {
      &-box {
        display: flex;
        align-items: flex-end;
        gap: s(5);
        margin-bottom: s(3);
      }
      & svg {
        @include block(s(22));
      }
      &--prev svg {
        transform: rotate(180deg);
      }
    }
  }
  &__review {
    display: flex;
    flex-direction: column;
    margin-bottom: s(15);
    &:hover,
    &:active {
      & > svg path {
        fill: var(--red);
      }
      & .mass-media__review-text {
        background-color: var(--red);
      }
    }
    & > svg {
      position: relative;
      top: s(-2);
      left: s(27);
      @include block(s(37), s(30));
      & path {
        @include transition(fill);
      }
    }
    &-text {
      position: relative;
      flex-direction: column;
      display: inline-flex;
      text-transform: uppercase;
      overflow: hidden;
      min-height: s(149);
      padding: s(20) s(25);
      border-radius: s(10);
      font-size: s(16);
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.04em;
      @include transition(background-color, var(--grey));
      & > span {
        @include text-ellipsis(3);
      }
      & > svg {
        &:first-child {
          @include block(s(16));
          margin-bottom: s(15);
        }
        &:last-child {
          position: absolute;
          bottom: s(-23);
          right: s(11);
          @include block(s(128));
        }
      }
    }
  }
  &__link {
    display: inline-flex;
    align-items: center;
    &:hover,
    &:active {
      & .mass-media__link-desc > span:last-child {
        color: var(--red);
      }
      & .mass-media__logo {
        transform: scale(1.05);
      }
    }
    &-desc {
      display: inline-flex;
      flex-direction: column;
      letter-spacing: 0.025em;
      & > span {
        font-family: 'GraphikLCG', sans-serif;
        font-size: s(20);
        font-weight: 300;
        line-height: 130%;
        @include transition(color);
        &:first-child {
          margin-bottom: s(5);
        }
        &:last-child {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
  &__logo {
    @include img(s(70), s(70));
    margin-right: s(20);
    @include transition(transform, scale(1));
  }
  @include mobile {
    position: relative;
    padding-top: s-md(117);
    padding-bottom: s-md(80);
    padding-right: 0;
    &__title {
      letter-spacing: -0.03em;
      &-box {
        padding-left: 0;
        padding-bottom: s-md(30);
      }
    }
    &__slider {
      &-btn {
        &-box {
          position: absolute;
          z-index: 5;
          left: s-md(16);
          bottom: 0;
          gap: s-md(16);
          margin-bottom: 0;
        }
        & > svg {
          @include block(s-md(20));
        }
      }
    }
    &__slide {
      width: 100;
      max-width: s-md(301);
    }
    &__review {
      margin-bottom: s-md(2);
      & > svg {
        left: s-md(17);
        top: s-md(-3);
        @include block(s-md(27), s-md(22));
      }
      &-text {
        min-height: s-md(122);
        padding: s-md(13) s-md(16) s-md(16);
        border-radius: s-md(10);
        font-size: s-md(12);
        line-height: 140%;
        & > span {
          -webkit-line-clamp: 4;
        }
        & > svg {
          &:first-child {
            @include block(s-md(13), s-md(15));
            margin-left: s-md(3);
            margin-bottom: s-md(9);
          }
          &:last-child {
            right: s-md(11);
            bottom: s-md(-14);
            @include block(s-md(68.5), s-md(56));
          }
        }
      }
    }
    &__link {
      &-desc {
        margin-top: s-md(3);
        & > span {
          font-size: s-md(16);
          line-height: 130%;
          letter-spacing: 0.025em;
          &:first-child {
            margin-bottom: s-md(5);
          }
        }
      }
    }
    &__logo {
      @include block(s-md(54));
      margin-right: s-md(13);
    }
  }
}
