.history {
  padding-top: s(190);
  &__title {
    font-size: s(60);
    & .underline::after {
      bottom: s(3);
    }
    &-box {
      display: flex;
      justify-content: space-between;
      padding-bottom: s(85);
    }
  }
  &__slider {
    padding-left: 29.8rem;
    padding-bottom: s(500);
    &-btn {
      &-box {
        display: flex;
        align-items: flex-end;
        gap: s(5);
        margin-bottom: s(4);
      }
      & svg {
        @include block(s(22));
      }
      &--prev svg {
        transform: rotate(180deg);
      }
    }
  }
  &__slide {
    width: auto;
    border-bottom: s(1) solid #f3414e69;
    @include transition(all);
    &:not(:first-child, :last-child) {
      padding: 0 s(92) s(34);
    }
    &:first-child,
    &:last-child {
      padding-bottom: s(34);
    }
    &:first-child {
      padding-right: s(92);
    }
    &:last-child {
      padding-left: s(92);
      margin-right: s(118);
    }
    &.active {
      border-color: #e5544fc6;
      & .history__year {
        &--desc,
        &--icon::after {
          opacity: 1;
        }
      }
      & .history__desc-box {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__year {
    cursor: pointer;
    position: relative;
    &--desc {
      text-align: center;
      font-size: s(70);
      font-weight: 400;
      line-height: s(77);
      @include text-bg();
      @include transition(opacity, 0.2);
    }
    &--icon {
      bottom: s(-56);
      @include block(s(20));
      background-color: var(--red);
      transform: translateX(-50%);
      &,
      &::after {
        position: absolute;
        left: 50%;
        border-radius: 100%;
      }
      &::after {
        content: '';
        top: 50%;
        @include block(s(40));
        border: s(1) solid var(--red);
        transform: translate(-50%, -50%);
        @include transition(opacity, 0);
      }
    }
  }
  &__desc {
    text-transform: uppercase;
    font-size: s(18);
    line-height: 160%;
    letter-spacing: 0.04em;
    @include text-bg('w');
    &:not(:last-child) {
      margin-bottom: s(20);
      padding-bottom: s(20);
      border-bottom: s(1) solid var(--white);
    }
    &-box {
      position: absolute;
      visibility: hidden;
      bottom: s(-342);
      left: 50%;
      opacity: 0;
      width: s(451);
      border-radius: s(10);
      padding: s(30);
      background-color: var(--grey);
      transform: translateX(-50%);
      @include transitions((visibility, opacity));
    }
  }
  @include mobile {
    position: relative;
    padding-top: s-md(115);
    &__title {
      line-height: 108%;
      letter-spacing: -0.03em;
      &-box {
        padding-bottom: s-md(40);
      }
    }
    &__slider {
      padding-left: s-md(16);
      padding-bottom: s-md(311);
      &-btn {
        &-box {
          position: absolute;
          z-index: 5;
          left: s-md(16);
          bottom: 0;
          gap: s-md(16);
          margin-bottom: 0;
        }
        & > svg {
          @include block(s-md(20));
        }
      }
    }
    &__slide {
      position: initial !important;
      border-width: s-md(2);
      &:not(:first-child, :last-child) {
        padding: 0 s-md(35) s-md(21);
      }
      &:first-child,
      &:last-child {
        padding-bottom: s-md(21);
      }
      &:first-child {
        padding-right: s-md(35);
      }
      &:last-child {
        padding-left: s-md(35);
        margin-right: s-md(16);
        & .history__desc-box {
          left: initial;
          right: s-md(-8);
        }
      }
      &:nth-last-child(2) {
        & .history__desc-box {
          left: s-md(-35);
        }
      }
    }
    &__year {
      &--desc {
        font-size: s-md(29);
        line-height: s-md(32);
      }
      &--icon {
        bottom: s-md(-30.5);
        @include block(s-md(12));
        &::after {
          @include block(s-md(24));
        }
      }
    }
    &__desc {
      font-size: s-md(14);
      line-height: 150%;
      letter-spacing: 0.005em;
      &:not(:last-child) {
        border-width: s-md(1);
        padding-bottom: s-md(12);
        margin-bottom: s-md(12);
      }
      &-box {
        width: s-md(343);
        bottom: s-md(-228);
        left: 0;
        padding: s-md(24) s-md(16);
        border-radius: s-md(10);
        transform: translateX(0);
      }
    }
  }
}
