// Скрытие всех контроллеров
[class*='copyrights-pane'] {
  display: none !important;
}
// Темная тема для карты
[class*='ymaps-2'][class*='-ground-pane'] {
  filter: contrast(60%) grayscale(100%) invert(100%);
}

.ymaps-layers-pane .ymaps-tile-pane,
.ymaps-layers-pane .ymaps-scale-line {
  display: none !important;
}
