.private-equity {
  padding-top: s(190);
  &__title {
    text-transform: uppercase;
    margin-bottom: s(55);
    font-size: s(60);
    font-weight: 400;
    line-height: 110%;
    & span {
      color: #898a8e;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    padding-bottom: s(50);
    &--left {
      position: relative;
      display: flex;
      align-items: flex-end;
      & svg {
        position: absolute;
        left: s(-115);
        bottom: s(-17);
        @include block(s(653), s(299));
      }
    }
    &--right {
      width: 100%;
      max-width: s(1109);
      border-top: s(2) solid var(--white);
      padding-top: s(50);
    }
  }
  &__link {
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    font-size: s(20);
    font-weight: 700;
    color: var(--red);
  }
  &__desc {
    font-family: 'GraphikLCG';
    font-size: s(22);
    font-weight: 400;
    line-height: 131%;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(s(534), 1fr));
    gap: s(40);
  }
  &__card {
    position: relative;
    overflow: hidden;
    min-height: s(350);
    border-radius: s(10) s(10) 0 0;
    padding: s(50);
    background: url('../img/texture.svg'), var(--grey);
    background-size: 200%;
    &:nth-child(2) span {
      bottom: s(-156);
    }
    & span {
      position: absolute;
      bottom: s(-140);
      left: 0;
      @include block(100%);
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &-title {
      text-transform: uppercase;
      margin-bottom: s(30);
      font-size: s(18);
      font-weight: 700;
      line-height: s(25);
    }
    &-desc {
      font-family: 'GraphikLCG';
      font-size: s(22);
      font-weight: 400;
      line-height: 131%;
      letter-spacing: 0.02em;
    }
  }
  @include mobile {
    padding-top: s-md(116);
    &__title {
      margin-bottom: s-md(26);
      font-size: s-md(25);
      line-height: 111%;
    }
    &__content {
      position: relative;
      & > svg {
        position: absolute;
        top: s-md(-101);
        right: s-md(-7);
        @include block(s-md(311), s-md(174));
      }
      padding-bottom: s-md(33);
      &--right {
        padding-top: s-md(31);
        border-top: s-md(1) solid rgba(255, 255, 255, 0.3);
        & .private-equity__link {
          display: inline-block;
          font-size: s-md(16);
          line-height: 160%;
        }
      }
    }
    &__desc {
      margin-bottom: s-md(32);
      font-size: s-md(18);
      line-height: 128%;
      letter-spacing: 0.02em;
    }
    &__list {
      grid-template-columns: 100%;
      gap: s-md(16);
    }
    &__card {
      padding: s-md(33) s-md(16) s-md(30) s-md(16);
      min-height: s-md(165);
      border-radius: s-md(10) s-md(10) 0 0;
      & > span {
        top: 0;
        bottom: initial;
        left: initial;
        background-size: contain;
      }
      &:first-child > span {
        right: s-md(-246);
      }
      &:nth-child(2) > span {
        right: s-md(-235);
        bottom: initial;
      }
      &:nth-child(3) > span {
        right: s-md(-264);
      }
      &-title {
        margin-bottom: s-md(20);
        font-size: s-md(14);
      }
      &-desc {
        max-width: s-md(280);
        font-size: s-md(18);
        line-height: 129%;
      }
    }
  }
}
