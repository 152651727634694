.contacts {
  position: relative;
  &::before,
  &::after {
    position: absolute;
    content: '';
    left: 0;
    z-index: 10;
    @include block(100%, s(328));
    background: linear-gradient(180deg, #232325 0%, rgba(35, 35, 37, 0) 100%);
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
  &__map {
    @include block(100%, s(800));
  }
  &__content {
    position: absolute;
    top: s(138);
    left: s(-26);
    z-index: 11;
    border: s(1) solid var(--white);
    border-radius: s(10);
    padding: s(58) s(118) s(61) s(145);
    backdrop-filter: blur(s(6.5));
    background-color: rgba(255, 255, 255, 0.01);
  }
  &__title {
    text-transform: uppercase;
    margin-bottom: s(35);
    font-size: s(35);
    line-height: 110%;
  }
  &__item {
    &:first-child {
      & .contacts__icon {
        margin-right: s(20);
      }
    }
    &:not(:last-child) {
      margin-bottom: s(30);
    }
  }
  &__icon {
    position: relative;
    overflow: hidden;
    @include flex-50-50();
    @include block(s(58));
    min-width: s(58);
    margin-right: s(29);
    border-radius: s(10);
    @include transition(background-color, rgba(255, 255, 255, 0.22));
    &::before,
    &::after {
      position: absolute;
      content: '';
      left: 50%;
      border-radius: 100%;
      filter: blur(s(6.5));
    }
    &::before {
      top: 50%;
      @include block(s(22));
      background-color: rgba(255, 255, 255, 0.2);
      transform: translate(-50%, -50%);
    }
    &::after {
      bottom: s(-25);
      @include block(s(37));
      background-color: rgba(255, 255, 255, 0.3);
      transform: translateX(-50%);
    }
    & svg {
      @include block(s(24));
    }
  }
  &__desc {
    cursor: pointer;
    @include flex-50-y();
    text-transform: uppercase;
    max-width: s(465);
    font-style: normal;
    letter-spacing: 0.04em;
    @include transition(color);
    &:hover,
    &:active {
      color: var(--red);
      & .contacts__icon {
        background-color: var(--red);
      }
    }
  }
  @include mobile {
    &::before,
    &::after {
      @include block(100%, s-md(137));
    }
    &__map {
      @include block(100%, s-md(676));
    }
    &__content {
      top: initial;
      bottom: 0;
      left: s-md(-16);
      border-width: s-md(1);
      border-radius: s-md(10);
      padding: s-md(30) s-md(56) s-md(32) s-md(32);
      backdrop-filter: blur(s-md(6.5));
    }
    &__title {
      margin-bottom: s-md(25);
      font-size: s-md(22);
      line-height: 150%;
    }
    &__item {
      &:first-child {
        & .contacts__icon {
          margin-right: s-md(12);
        }
      }
      &:not(:last-child) {
        margin-bottom: s-md(24.5);
      }
    }
    &__icon {
      @include block(s-md(50));
      min-width: s-md(50);
      margin-right: s-md(12);
      border-radius: s-md(10);
      &::before,
      &::after {
        filter: blur(s-md(6.5));
      }
      &::before {
        top: 60%;
        left: 60%;
        @include block(s-md(22));
      }
      &::after {
        bottom: s-md(-37);
        @include block(s-md(37));
      }
      & svg {
        @include block(s-md(20));
      }
    }
    &__desc {
      max-width: s-md(285);
      line-height: 125%;
    }
  }
}
