.priorities {
  padding-bottom: s(341);
  &__title {
    &-box {
      @include flex-50-y();
      justify-content: space-between;
      margin-left: s(-5);
      padding-bottom: s(52);
    }
  }
  &__link {
    margin-bottom: s(8);
    padding: s(14) s(28);
    @include transitions((background-color, border-color));
    &:hover,
    &:active {
      border-color: var(--red);
      background-color: var(--red);
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(s(317), 1fr));
    gap: s(24);
  }
  &__item {
    position: relative;
    @include flex-50-x();
    overflow: hidden;
    min-height: s(345);
    border-radius: s(10);
    padding: s(60) s(30);
    &:nth-child(odd) {
      background-color: var(--red);
    }
    &:nth-child(even) {
      background-color: var(--grey);
    }
    & > span {
      position: absolute;
      top: 0;
      left: 0;
      @include block(100%);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  &__desc {
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;
    font-size: s(18);
    line-height: 155%;
    font-weight: 700;
    letter-spacing: 0.04em;
    @include text-bg('w');
  }
  @include mobile {
    position: relative;
    padding-bottom: s-md(76);
    margin-bottom: s-md(130.5);
    &__title-box {
      margin-left: 0;
      padding-bottom: s-md(31);
    }
    &__link {
      position: absolute;
      bottom: 0;
      right: s-md(16);
      margin-bottom: 0;
      padding: s-md(15) s-md(23);
    }
    &__list {
      grid-template-columns: 1fr;
      gap: s-md(12);
    }
    &__item {
      align-items: center;
      justify-content: flex-start;
      min-height: s-md(60);
      border-radius: s-md(10);
      padding: s-md(10) s-md(16);
    }
    &__desc {
      font-size: s-md(14);
      letter-spacing: 0;
    }
  }
}
