// Заливка текста
@mixin text-bg($color: 'red') {
  @if $color == 'red' {
    background: linear-gradient(180deg, #e5554f 19.29%, #e5554f 43.27%, #8c2b27 94.12%);
  } @else {
    background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(95deg, #dda95b -19.78%, #d62b2c 55.97%, #3b63df 110.96%);
  }
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

// Многоточие в конце строки
@mixin text-ellipsis($line: none) {
  @if $line != none {
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  } @else {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  overflow: hidden;
}

// Бегущая строка
@mixin ticker($attr, $delay: 15s) {
  white-space: nowrap;
  animation: ticker $delay infinite linear;
  &::after {
    content: '\20'attr($attr);
  }
  @keyframes ticker {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
}
