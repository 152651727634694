@import 'common';

@import './_main/**/*';
@import './_about/**/*';
@import './_smartmoney/**/*';

.projects-main {
  overflow: hidden;
  padding-top: s(89);
  padding-bottom: s(150);
  &__container {
    position: relative;
    & > svg {
      position: absolute;
      z-index: -1;
      top: s(22);
      right: s(-64);
      @include block(s(1683), s(420));
    }
  }
  &__title {
    margin-bottom: s(82);
    margin-left: s(-5);
    font-size: s(82);
    font-weight: 700;
    color: var(--red);
  }
  &__tabs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1;
    gap: s(25);
  }
  &__tab {
    &-link {
      cursor: pointer;
      border-radius: s(1000);
      border: s(1) solid var(--white);
      padding: s(14) s(27);
      background-color: transparent;
      @include transitions((border-color, background-color));
      & > strong {
        line-height: s(18);
        text-transform: uppercase;
        letter-spacing: 0.04em;
      }
      &.active,
      &:hover,
      &:active {
        border-color: var(--red);
        background-color: var(--red);
      }
    }
  }
  &__shadow {
    &--left,
    &--right {
      position: absolute;
      bottom: s(75);
      z-index: 5;
    }
    &--left {
      left: s(-140);
      @include block(s(1122), s(429));
      background: linear-gradient(89.99deg, #232325 40.35%, rgba(35, 35, 37, 0) 99.08%);
      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: s(164);
        @include block(s(922), s(453));
        filter: blur(s(2.5));
        backdrop-filter: blur(s(1));
        background-color: rgba(35, 35, 37, 0.01);
      }
    }
    &--right {
      right: s(-120);
      @include block(s(581), s(427));
      background: linear-gradient(91.18deg, #232325 -3.95%, rgba(35, 35, 37, 0) 95.9%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
  &__slider {
    padding-top: s(310);
    padding-bottom: s(100);
    padding-left: s(500);
    &-box {
      user-select: none;
      position: relative;
    }
  }
  &__slide {
    height: s(350);
  }
  &__card {
    position: absolute;
    cursor: pointer;
    @include block(s(274), 100%);
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: s(10);
    border: s(2) solid #808085;
    background: linear-gradient(159.86deg, #53535b -10%, #323237 42.71%, #323237 49.77%, #323237 57.7%, #53535b 111.04%);
    @include transition(transform, matrix(0.98, -0.18, 0, 1, 0, 0));
    &::after,
    &::before {
      position: absolute;
      content: '';
      left: 0;
      opacity: 0;
    }
    &::before {
      top: 0;
      z-index: 2;
      @include block(100%);
      background-color: rgba(229, 85, 79, 0.1);
      @include transition(opacity);
    }
    &::after {
      visibility: hidden;
      bottom: s(-267);
      z-index: -1;
      @include block(100%, s(267));
      background: linear-gradient(180deg, rgba(157, 22, 46, 0.12) 15.16%, rgba(229, 85, 79, 0) 85.93%);
      @include transitions((visibility, opacity));
    }
    &.hover {
      transform: matrix(0.98, -0.18, 0, 1, 0, -197);
      &::before,
      &::after {
        opacity: 1;
      }
      &::after {
        visibility: visible;
      }
    }
    &:hover,
    &:active,
    &.active {
      border-color: rgba(229, 85, 79, 0.31);
      &::before {
        opacity: 1;
      }
    }
  }
  &__logo {
    &-box {
      position: relative;
      overflow: hidden;
      @include flex-50-50();
      @include block(100%);
      &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        @include block(150%);
        background: url('../img/texture-card.svg');
        transform: rotate(9deg) translate(-50%, -50%);
      }
      & > span {
        &::after,
        &::before {
          position: absolute;
          content: '';
          @include block(s(49), s(119));
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
        &::after {
          top: s(-8);
          right: s(20);
          background-image: url("data:image/svg+xml,%3Csvg width='52' height='129' viewBox='0 0 52 129' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M50.0791 119.865V0.864499M25.9894 91.5017V5.32927M1.89961 63.1389V9.79404' stroke='url(%23paint0_linear_1051_164259)' stroke-width='3'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1051_164259' x1='25.9894' y1='5.32927' x2='50.5242' y2='137.842' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23808085'/%3E%3Cstop offset='1' stop-color='%23808085' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        }
        &::before {
          bottom: s(-8);
          left: s(20);
          background-image: url("data:image/svg+xml,%3Csvg width='52' height='129' viewBox='0 0 52 129' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.1416 9.01343V128.013M26.2313 37.3762V123.549M50.3211 65.7391V119.084' stroke='url(%23paint0_linear_1051_164257)' stroke-width='3'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1051_164257' x1='26.2313' y1='123.549' x2='1.6965' y2='-8.96386' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23808085'/%3E%3Cstop offset='1' stop-color='%23808085' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        }
      }
    }
    @include img(s(153));
    transform: rotate3d(1, 1, 1, 16deg);
  }
  &__content {
    position: absolute;
    overflow: hidden;
    top: s(100);
    left: s(-26);
    width: 100%;
    z-index: 10;
    max-width: s(822);
    border: s(1) solid var(--white);
    border-radius: s(10);
    padding: s(60) s(78) s(60) s(144);
    backdrop-filter: blur(s(6.5));
    background: linear-gradient(271.2deg, rgba(211, 58, 69, 0.15) -27.32%, rgba(0, 0, 0, 0) 132.97%), rgba(255, 255, 255, 0.01);
    @include transitions((visibility, opacity));
    &.hidden {
      visibility: hidden;
      opacity: 0;
    }
    &-box {
      display: none;
      &.active {
        display: block;
        animation: translateX 0.4s ease-in-out;
        @keyframes translateX {
          from {
            opacity: 0;
            transform: translateX(-100%);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
    }
    &-link {
      text-transform: uppercase;
      margin-bottom: s(37);
      border: s(1) solid var(--white);
      border-radius: s(30);
      padding: s(8) s(26);
      font-size: s(14);
      line-height: 160%;
      letter-spacing: 0.04em;
      @include text-bg('w');
      @include transitions(border-color);
      &:hover,
      &:active {
        border-color: var(--red);
      }
    }
    &-title {
      text-transform: uppercase;
      margin-bottom: s(32);
      border-width: s(1) 0;
      border-style: solid;
      border-color: var(--white);
      padding: s(20) 0;
      font-size: s(26);
      line-height: 160%;
      letter-spacing: 0.04em;
    }
    &-desc {
      margin-bottom: s(32);
      font-family: 'GraphikLCG', sans-serif;
      font-weight: 400;
      font-size: s(20);
      line-height: 130%;
      letter-spacing: 0.02em;
      color: rgba(255, 255, 255, 0.5);
    }
    &-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(s(225), 1fr));
      gap: s(30) s(44);
    }
    &-progress {
      display: inline-flex;
      flex-direction: column;
      &--count {
        &-left,
        &-right {
          @include text-bg('w');
          font-weight: 700;
        }
        &-left {
          @include text-bg('w');
          font-size: s(35);
          line-height: 110%;
        }
        &-right {
          font-size: s(21);
        }
      }
      &--desc {
        font-family: 'GraphikLCG', sans-serif;
        font-size: s(20);
        font-weight: 300;
        line-height: 130%;
      }
    }
  }
  @include mobile {
    padding-top: s-md(56);
    padding-bottom: s-md(120);
    &__container {
      & > svg {
        top: 1.2rem;
        right: 1.6rem;
        @include block(s-md(209), s-md(149));
      }
    }
    &__title {
      margin-left: 0;
      margin-bottom: s-md(33);
      font-size: s-md(29);
    }
    &__dropdown {
      position: relative;
      &::before {
        position: fixed;
        visibility: hidden;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 50;
        @include block(100vw, 100vh);
        background-color: rgba(35, 35, 37, 0.85);
        @include transitions((opacity, visibility));
      }
      &.active::before {
        visibility: visible;
        opacity: 1;
      }
      &-btn {
        position: relative;
        text-align: left;
        text-transform: uppercase;
        z-index: 60;
        width: 100%;
        padding: s-md(14) s-md(40) s-md(10) s-md(24);
        border-radius: s-md(1000);
        border: s-md(1) solid var(--red);
        font-size: s-md(13);
        font-weight: 700;
        letter-spacing: 0.04em;
        background-color: var(--red);
        & > span {
          @include text-ellipsis();
        }
        &::after {
          position: absolute;
          content: '';
          top: 50%;
          right: s-md(15);
          @include block(s-md(17), s-md(19));
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='19' viewBox='0 0 16 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Arrow 7' d='M8.70711 17.7428C8.31658 18.1333 7.68342 18.1333 7.29289 17.7428L0.928933 11.3789C0.538408 10.9883 0.538408 10.3552 0.928933 9.96465C1.31946 9.57412 1.95262 9.57412 2.34315 9.96465L8 15.6215L13.6569 9.96465C14.0474 9.57412 14.6805 9.57412 15.0711 9.96465C15.4616 10.3552 15.4616 10.9883 15.0711 11.3789L8.70711 17.7428ZM9 4.37114e-08L9 17.0357L7 17.0357L7 -4.37114e-08L9 4.37114e-08Z' fill='white'/%3E%3C/svg%3E%0A");
          @include transition(transform, translateY(-48%) rotateX(0));
        }
        &.active::after {
          transform: translateY(-48%) rotateX(180deg);
        }
      }
    }
    &__tab {
      &:not(:last-child) {
        border-bottom: s-md(1) solid rgba(255, 255, 255, 0.3);
      }
      &-link {
        @include flex-50-y();
        border: none;
        padding: s-md(15.6) 0;
        border-radius: 0;
        gap: s-md(16);
        font-size: s-md(13);
        & > span {
          position: relative;
          display: block;
          @include block(s-md(24));
          border: s-md(2) solid #ffffff4d;
          @include transition(border-color);
          &,
          &::after {
            border-radius: 100%;
          }
          &::after {
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            @include block(s-md(16));
            transform: translate(-50%, -50%);
            background-color: var(--red);
            @include transition(opacity, 0);
          }
        }
        & > strong {
          line-height: 4.8rem;
          @include text-ellipsis();
        }
        &:hover,
        &:active {
          background-color: transparent;
        }
        &.active {
          background-color: transparent;
          & > span {
            border-color: var(--red);
            &::after {
              opacity: 1;
            }
          }
        }
      }
      &s {
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        z-index: 55;
        overflow: auto;
        @include block(100%, s-md(330));
        padding: s-md(53) s-md(24) 0;
        border-radius: s-md(23) s-md(23) s-md(10) s-md(10);
        background-color: #303033;
      }
    }
    &__shadow {
      &--left,
      &--right {
        top: s-md(150);
        bottom: initial;
        @include block(s-md(110), s-md(228));
        background: linear-gradient(90deg, #232325 7.81%, rgba(35, 35, 37, 0) 100%);
      }
      &--left {
        left: s-md(-10);
        &::before {
          content: none;
          display: none;
        }
      }
      &--right {
        right: s-md(-10);
      }
    }
    &__slider {
      margin-left: s-md(-80);
      padding-top: s-md(163);
      padding-left: 0;
    }
    &__slide {
      height: s-md(200);
    }
    &__card {
      width: s-md(156);
      border-radius: s-md(10);
      border-width: s-md(2);
      &.hover,
      &.active {
        transform: matrix(0.98, -0.18, 0, 1, 0, -116);
        border-color: rgb(229 85 79 / 80%);
      }
    }
    &__logo {
      width: 16.8rem;
      &-box {
        & > span {
          &::before,
          &::after {
            width: 6.6rem;
            height: 14.6rem;
          }
          &::before {
            bottom: -1.4rem;
            left: 1.8rem;
            background-image: url("data:image/svg+xml,%3Csvg width='31' height='74' viewBox='0 0 31 74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Group 35106'%3E%3Cpath id='Vector' d='M1.76562 5.15063V73.1506M15.5312 21.358V70.5993M29.2968 37.5653V68.048' stroke='url(%23paint0_linear_1318_49810)' stroke-width='3'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1318_49810' x1='15.5312' y1='70.5993' x2='1.51128' y2='-5.1221' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23808085'/%3E%3Cstop offset='1' stop-color='%23808085' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
          }
          &::after {
            top: -1.4rem;
            right: 2.2rem;
            background-image: url("data:image/svg+xml,%3Csvg width='31' height='74' viewBox='0 0 31 74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Group 35107'%3E%3Cpath id='Vector' d='M29.0156 68.3511V0.351072M15.2501 52.1438V2.90237M1.48449 35.9364V5.45367' stroke='url(%23paint0_linear_1318_49812)' stroke-width='3'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1318_49812' x1='15.2501' y1='2.90237' x2='29.27' y2='78.6238' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23808085'/%3E%3Cstop offset='1' stop-color='%23808085' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
          }
        }
      }
    }
    &__content {
      position: static;
      max-width: s-md(343);
      margin: 0 auto;
      padding: s-md(30) s-md(16) s-md(34);
      border-radius: s-md(10);
      border-width: s-md(1);
      background: linear-gradient(270deg, rgba(211, 58, 69, 0.15) 0%, rgba(0, 0, 0, 0) 100%), rgba(255, 255, 255, 0.01);
      backdrop-filter: blur(s-md(6.5));
      &-link {
        margin-bottom: s-md(24);
        padding: s-md(8) s-md(21);
        border-width: s-md(1);
        border-radius: s-md(30);
        font-size: s-md(12);
      }
      &-title {
        margin-bottom: s-md(18);
        padding: s-md(10) 0;
        border-width: s-md(1) 0;
        font-size: s-md(20);
      }
      &-desc {
        margin-bottom: s-md(26);
        font-size: s-md(18);
        line-height: 129%;
      }
      &-list {
        grid-template-columns: 100%;
        gap: s-md(27);
      }
      &-progress {
        align-items: flex-end;
        flex-direction: row;
        gap: s-md(17);
        &--count {
          &-left {
            font-size: s-md(22);
            letter-spacing: -0.03em;
          }
          &-right {
            font-size: s-md(17);
            letter-spacing: -0.02em;
          }
        }
        &--desc {
          margin-bottom: s-md(-1);
          font-size: s-md(16);
          letter-spacing: 0.035em;
        }
      }
    }
  }
}

.article {
  overflow: hidden;
  position: relative;
  padding-top: s(133);
  &__container {
    display: flex;
    justify-content: space-between;
  }
  &__cube {
    position: absolute;
    & > span {
      display: block;
      position: absolute;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.05) 37.97%, rgba(255, 255, 255, 0) 91.6%);
    }
    &--left {
      @include block(s(577), s(627));
      bottom: 0;
      left: s(-150);
      & > span {
        &:first-child {
          top: 0;
          left: s(100);
          transform: matrix(-0.87, 0.5, 0.87, 0.5, 0, 0);
          @include block(s(334), s(329));
        }
        &:nth-child(2) {
          left: s(-50);
          bottom: s(87);
          transform: matrix(-0.87, -0.5, 0, 1, 0, 0);
          @include block(s(334), s(294));
        }
        &:last-child {
          right: 0;
          bottom: s(88);
          transform: matrix(-0.87, 0.5, 0, 1, 0, 0);
          @include block(s(334), s(292));
        }
      }
    }
    &--right {
      right: s(-252);
      top: s(273);
      @include block(s(741), s(805));
      & > span {
        &:first-child {
          top: 0;
          left: s(155);
          @include block(s(428), s(422));
          transform: matrix(0.87, 0.5, -0.87, 0.5, 0, 0);
        }
        &:nth-child(2) {
          left: s(-29);
          bottom: s(113);
          @include block(s(428), s(375));
          transform: matrix(0.87, 0.5, 0, 1, 0, 0);
        }
        &:last-child {
          right: s(-25);
          bottom: s(108);
          @include block(s(428), s(377));
          transform: matrix(0.87, -0.5, 0, 1, 0, 0);
        }
      }
    }
  }
  &__content {
    width: 100%;
    max-width: s(1005);
    margin-top: s(-2);
    padding-right: s(63);
    &--left {
      display: flex;
      flex-direction: column;
    }
  }
  &__back {
    margin-bottom: s(61);
    &:hover,
    &:active {
      color: var(--red);
    }
    &--icon {
      @include flex-50-50();
      @include block(s(53));
      margin-bottom: s(20);
      border: s(0.5) solid var(--white);
      border-radius: 100%;
      & svg {
        @include block(s(22));
        transform: rotate(180deg);
      }
    }
    &--desc {
      text-transform: uppercase;
      font-family: 'GraphikLCG';
      font-weight: 300;
      font-size: s(18);
      line-height: 120%;
      @include transition(color);
    }
  }
  &__date {
    text-transform: uppercase;
    font-size: s(18);
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.04em;
  }
  &__title {
    text-transform: uppercase;
    margin-bottom: s(45);
    font-size: s(35);
    font-weight: 700;
    line-height: 110%;
    color: var(--red);
  }
  &__desc {
    display: inline-flex;
    flex-direction: column;
    text-align: justify;
    font-family: 'GraphikLCG';
    font-weight: 400;
    font-size: s(24);
    line-height: 140%;
    & > span:not(:last-child) {
      margin-bottom: s(23);
    }
  }
  &__link {
    text-transform: uppercase;
    padding: s(8) s(27) s(7);
    border: s(1) solid var(--white);
    border-radius: s(30);
    font-size: s(14);
    line-height: 160%;
    letter-spacing: 0.04em;
    background-color: rgba(255, 255, 255, 0.15);
    @include transitions((background-color, border-color));
    &:hover,
    &:active {
      border-color: var(--red);
      background-color: var(--red);
    }
  }
  @include mobile {
    padding-top: s-md(58);
    &__container {
      flex-direction: column;
    }
    &__cube {
      &--left {
        display: none;
      }
      &--right {
        top: s-md(563);
        right: s-md(-160);
      }
    }
    &__content {
      padding-right: 0;
      &--left {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: s-md(30);
      }
    }
    &__back {
      margin-bottom: 0;
      &--icon {
        @include block(s-md(53));
        margin-bottom: s-md(11);
        & > svg {
          @include block(s-md(20));
        }
      }
      &--desc {
        font-size: s-md(14);
        line-height: 120%;
      }
    }
    &__date {
      padding-top: s-md(60);
      font-size: s-md(14);
      line-height: 150%;
      letter-spacing: 0;
    }
    &__title {
      margin-bottom: s-md(17);
      font-size: s-md(22);
      line-height: 132%;
    }
    &__desc {
      text-align: initial;
      font-size: s-md(18);
      line-height: 128%;
      letter-spacing: 0.02em;
      & > span:not(:last-child) {
        margin-bottom: s-md(22);
      }
    }
    &__link {
      position: absolute;
      top: s-md(57.5);
      right: s-md(16);
      border-radius: s-md(30);
      padding: s-md(9) s-md(21);
      font-size: s-md(12);
      line-height: 160%;
    }
  }
}

.articles {
  padding-top: s(192);
  padding-bottom: s(202);
  &__title {
    font-size: s(60);
    & .underline::after {
      bottom: s(3);
    }
    &-box {
      display: flex;
      justify-content: space-between;
      padding-bottom: s(55);
    }
  }
  &__slider {
    &-btn {
      &-box {
        display: flex;
        align-items: flex-end;
        gap: s(5);
        margin-bottom: s(4);
      }
      & svg {
        @include block(s(22));
      }
      &--prev svg {
        transform: rotate(180deg);
      }
    }
  }
  &__slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: s(424);
    padding: s(34) s(25) s(36);
    border: s(1) solid var(--white);
    border-radius: s(10);
    &:first-child {
      & .articles__img {
        right: s(-289);
      }
    }
    &:nth-child(3) {
      & .articles__img {
        right: s(-177);
      }
    }
  }
  &__article {
    text-transform: uppercase;
    @include text-ellipsis(5);
    font-size: s(26);
    line-height: 160%;
    letter-spacing: 0.04em;
  }
  &__link {
    text-transform: uppercase;
    padding: s(9) s(27) s(7);
    border: s(1) solid var(--white);
    border-radius: s(30);
    font-size: s(14);
    line-height: 160%;
    letter-spacing: 0.04em;
  }
  &__img {
    position: absolute;
    right: s(-250);
    bottom: s(36);
    @include img(s(535), s(245));
    object-fit: contain;
  }
  @include mobile {
    position: relative;
    padding-top: s-md(115);
    padding-bottom: s-md(86);
    margin-bottom: s-md(119);
    &__title {
      width: 100%;
      max-width: s-md(338);
      font-size: s-md(25);
      line-height: 110%;
      &-box {
        padding-bottom: s-md(26);
      }
    }
    &__slider {
      &-btn {
        &-box {
          position: absolute;
          z-index: 5;
          left: s-md(16);
          bottom: 0;
          gap: s-md(16);
          margin-bottom: 0;
        }
        & > svg {
          @include block(s-md(20));
        }
      }
    }
    &__slide {
      min-height: s-md(239);
      padding: s-md(25) s-md(28) s-md(25) s-md(16);
    }
    &__article {
      font-size: s-md(14);
      line-height: 140%;
    }
    &__link {
      border-radius: s-md(30);
      padding: s-md(8) s-md(21);
      font-size: s-md(12);
      line-height: 160%;
    }
    &__img {
      right: s-md(-185) !important;
      bottom: s-md(22);
      @include block(s-md(344), s-md(157.5));
    }
  }
}

.contacts-main {
  padding-top: s(122);
  padding-bottom: s(206);
  &__title {
    margin-bottom: s-md(23);
    font-size: s(60);
    line-height: 110%;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    border-top: s(1) solid var(--white);
  }
  &__item {
    position: relative;
    padding-top: s(41);
    &:first-child,
    &:nth-child(2) {
      &::before {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        @include block(s(2), 100%);
        background-image: url("data:image/svg+xml,%3Csvg width='2' height='115' viewBox='0 0 2 115' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-0.5' x2='114.004' y2='-0.5' transform='matrix(-0.0087715 -0.999962 0.999952 -0.00977309 2 114)' stroke='url(%23paint0_linear_869_51078)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_869_51078' x1='118.167' y1='6.18306e+06' x2='-72.3736' y2='6.18306e+06' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='0.716667' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    &:first-child {
      padding-right: s(78);
    }
    &:nth-child(2) {
      padding-right: s(84);
      &::before {
        right: s(6);
      }
    }
  }
  &__icon {
    position: relative;
    overflow: hidden;
    @include flex-50-50();
    @include block(s(70));
    min-width: s(70);
    margin-right: s(28);
    border-radius: s(10);
    @include transition(background-color, rgba(255, 255, 255, 0.22));
    &::before,
    &::after {
      position: absolute;
      content: '';
      left: 50%;
      border-radius: 100%;
      filter: blur(s(6.5));
    }
    &::before {
      top: 50%;
      @include block(s(32));
      background-color: rgba(255, 255, 255, 0.4);
      transform: translate(-50%, -50%);
    }
    &::after {
      bottom: s(-25);
      @include block(s(37));
      background-color: rgba(255, 255, 255, 0.3);
      transform: translateX(-50%);
    }
    & svg {
      @include block(s(28));
    }
  }
  &__desc {
    cursor: pointer;
    @include flex-50-y();
    text-transform: uppercase;
    max-width: s(622);
    font-size: s(22);
    font-style: normal;
    line-height: 160%;
    letter-spacing: 0.04em;
    @include transition(color);
    &:hover,
    &:active {
      color: var(--red);
      & .contacts-main__icon {
        background-color: var(--red);
      }
    }
  }
  &__map {
    @include block(100%, s(800));
    &-container {
      position: relative;
      &::before,
      &::after {
        position: absolute;
        content: '';
        left: 0;
        z-index: 10;
        @include block(100%, s(328));
        background: linear-gradient(180deg, #232325 0%, rgba(35, 35, 37, 0) 100%);
      }
      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
        transform: matrix(1, 0, 0, -1, 0, 0);
      }
    }
  }
  @include mobile {
    padding-top: s-md(51);
    padding-bottom: s-md(80);
    &__title {
      margin-bottom: s-md(25);
      letter-spacing: -0.03em;
    }
    &__list {
      border: none;
      flex-direction: column;
    }
    &__item {
      padding-right: 0 !important;
      &:not(:last-child) {
        padding: s-md(20) 0;
      }
      &:last-child {
        padding-top: s-md(20);
      }
      &::before {
        content: none !important;
      }
      &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        @include block(100%, s-md(1));
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='343' height='1' viewBox='0 0 343 1' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-0.5' x2='343' y2='-0.5' transform='matrix(-1 0 0 1 343 1)' stroke='url(%23paint0_linear_897_58930)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_897_58930' x1='355.525' y1='6.18306e+06' x2='-217.747' y2='6.18306e+06' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='0.716667' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
      }
    }
    &__icon {
      @include block(s-md(50));
      min-width: s-md(50);
      margin-right: s-md(12);
      border-radius: s-md(10);
      &::before,
      &::after {
        filter: blur(s-md(6.5));
      }
      &::before {
        top: 60%;
        left: 60%;
        @include block(s-md(22));
      }
      &::after {
        bottom: s-md(-37);
        @include block(s-md(37));
      }
      & svg {
        @include block(s-md(20));
      }
    }
    &__desc {
      max-width: s-md(280);
      font-size: s-md(14);
      line-height: 130%;
    }
    &__map {
      @include block(100%, s-md(676));
      &-container {
        &::before,
        &::after {
          @include block(100%, s-md(137));
        }
      }
    }
  }
}

.error-404 {
  position: relative;
  overflow: hidden;
  padding-top: s(215);
  padding-bottom: s(110);
  &::before {
    position: absolute;
    content: '';
    top: s(115);
    left: 86.5rem;
    z-index: 1;
    @include block(s(558), s(854));
    background: linear-gradient(180deg, rgba(35, 35, 37, 0) 0%, #232325 77.96%);
  }
  &__slogan {
    bottom: s(132);
    right: s(118);
    font-size: s(150);
  }
  &__img {
    position: relative;
    top: s(-40);
    left: s(-25);
    z-index: 10;
    @include img(s(500), s(490));
    &-box {
      position: relative;
      @include flex-50-50();
      &::before,
      &::after {
        position: absolute;
        content: '';
      }
      &::before {
        top: s(155);
        left: -14.5rem;
        @include block(s(1288), s(265));
        z-index: 2;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0) 100%);
      }
      &::after {
        top: s(50);
        right: 14.5rem;
        @include block(s(960), s(297));
        background: linear-gradient(89deg, rgba(155, 50, 46, 0.12) 16.85%, rgba(157, 22, 46, 0.12) 16.86%, rgba(229, 85, 79, 0) 100%);
      }
      & span {
        position: relative;
        z-index: 10;
        text-transform: uppercase;
        font-size: s(270);
        font-weight: 700;
        line-height: 110%;
      }
      & svg {
        position: absolute;
        top: s(-70);
        left: s(-267);
        z-index: 1;
        @include img(s(2459), s(602));
      }
    }
    &--shadow {
      position: absolute;
      bottom: s(-240);
      right: 98.5rem;
      @include block(s(355), s(313));
    }
  }
  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: s(-28);
    z-index: 10;
    margin: 0 auto;
  }
  &__desc {
    text-transform: uppercase;
    margin-bottom: s(35);
    font-weight: 700;
    font-size: s(26);
    line-height: 160%;
    letter-spacing: 0.04em;
  }
  &__link {
    @include transitions((border-color, background-color));
    &:hover,
    &:active {
      border-color: var(--red);
      background-color: var(--red);
    }
  }
  @include mobile {
    padding-bottom: 0;
    &::before {
      left: s-md(50);
    }
    &__img {
      left: s(-15);
      @include img(s(350), s(340));
      &-box {
        & svg {
          top: 5rem;
          left: -56.7rem;
          height: 29.2rem;
        }
        & span {
          font-size: s(200);
        }
        &::after {
          top: 1rem;
          right: s-md(-300);
          height: 19.7rem;
        }
        &::before {
          top: 8.5rem;
          left: -92.5rem;
          height: 19.7rem;
        }
      }
    }
    &__content {
      top: 0;
      padding-top: s-md(30);
    }
    &__desc {
      margin-bottom: 6.5rem;
      font-size: s-md(16);
    }
  }
}

.contact-page,
.about-page,
.projects-page,
.article-page,
.smartmoney-page {
  & .contact {
    &__box--left {
      padding-top: s(37);
    }
    &__title {
      margin-bottom: s(28);
      font-size: s(60);
      line-height: 110%;
    }
  }
  @include mobile {
    & .contact {
      margin-bottom: 0;
    }
    & .contact {
      &__box--left {
        padding-top: s-md(26);
      }
      &__title {
        margin-bottom: s-md(10);
        letter-spacing: -0.03em;
      }
    }
  }
}
