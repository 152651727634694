.contact {
  margin-bottom: s(200);
  &__container {
    display: flex;
    overflow: hidden;
    border-radius: s(5);
    padding: s(70) s(100) s(80);
    background-color: #303032;
  }
  &__box {
    &--left,
    &--right {
      flex-basis: 50%;
    }
    &--left {
      position: relative;
      padding-top: s(34);
      border-right: s(1) solid rgba(255, 255, 255, 0.2);
      & svg {
        position: absolute;
        top: s(380);
        left: s(-1008);
        @include block(s(3502), s(350));
      }
    }
  }
  &__title {
    margin-bottom: s(20);
  }
  &__desc {
    width: 100%;
    max-width: s(410);
    font-family: 'GraphikLCG';
    font-size: s(20);
    font-weight: 300;
    line-height: 130%;
    letter-spacing: 0.015em;
  }
  &__form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: s(38) 0 0 s(60);
    &-desc {
      width: 100%;
      max-width: s(570);
      margin-top: s(50);
      margin-bottom: s(47);
      font-family: 'GraphikLCG';
      font-size: s(20);
      font-weight: 300;
      line-height: 130%;
      letter-spacing: 0.015em;
      color: rgba(255, 255, 255, 0.3);
    }
    &-box {
      display: flex;
      overflow: hidden;
      flex-wrap: wrap;
      gap: s(23) s(20);
      max-height: 0;
      padding-top: 0;
      @include transition(all);
      transition-timing-function: linear;
      will-change: max-height, padding-top;
      &.active {
        max-height: s(500);
        padding-top: s(15);
      }
      & .contact__label {
        flex-basis: s(330);
        margin-bottom: 0 !important;
      }
      & .contact__input {
        padding: s(10) 0 s(16);
      }
    }
  }
  &__additionally {
    position: relative;
    text-transform: uppercase;
    font-size: s(16);
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.04em;
    &::after {
      position: absolute;
      content: '';
      bottom: s(3);
      left: 0;
      @include block(100%, s(1));
      background-color: var(--white);
      @include transition(opacity, 0);
    }
    &.active::after {
      opacity: 1;
    }
  }
  &__label {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: s(28);
    & .placeholder {
      position: absolute;
      overflow: hidden;
      opacity: 0;
      font-size: 0;
      @include block(0);
    }
    &:last-of-type {
      margin-bottom: s(45);
    }
  }
  &__input {
    width: 100%;
    padding: s(10) 0;
    border-width: 0 0 s(1) 0;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.3);
    @include transitions((border-color, border-width));
    &,
    &::placeholder {
      text-transform: uppercase;
      font-size: s(16);
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.04em;
    }
    & + span {
      position: absolute;
      left: 0;
      bottom: 0;
      @include block(100%, s(3));
      background-color: #d33a45;
      @include transition(opacity, 0);
    }
    &:hover,
    &:focus,
    &:active {
      & + span {
        opacity: 1;
      }
    }
  }
  &__select {
    & + .ui-selectmenu-button {
      position: relative;
      width: 100%;
      max-width: s(330);
      margin: 0;
      border-width: 0 0 s(1) 0;
      border-style: solid;
      border-color: rgba(255, 255, 255, 0.3);
      border-radius: 0;
      padding: s(10) 0 s(16);
      background: transparent;
      color: rgba(255, 255, 255, 0.3) !important;
      &::after {
        position: absolute;
        display: block;
        left: 0;
        bottom: 0;
        content: '';
        @include block(100%, s(3));
        background-color: #d33a45;
        @include transition(opacity, 0);
      }
      &:hover::after,
      &:active::after {
        opacity: 1;
      }
      & * {
        font-family: 'EuropeExt', sans-serif !important;
        text-transform: uppercase !important;
        font-size: s(16);
        font-weight: 700;
        line-height: 160%;
        letter-spacing: 0.04em;
      }
      &[aria-labelledby] {
        color: var(--white) !important;
      }
      &[aria-labelledby='ui-id-1'] {
        color: rgba(255, 255, 255, 0.3) !important;
      }
    }
  }
  &__btn {
    position: relative;
    overflow: hidden;
    padding: s(14) s(27);
    &--desc {
      position: relative;
      z-index: 2;
    }
    &--animate {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      z-index: 1;
      @include block(120%, 100%);
      & span {
        display: block;
        flex-basis: 33.3%;
        background-color: var(--red);
        transform: translateX(-100%);
        animation-name: translate;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-duration: 4s;
        @keyframes translate {
          0% {
            transform: translateX(-110%) skew(-45deg);
          }
          20% {
            transform: translateX(-110%) skew(-45deg);
          }
          40% {
            transform: translateX(-10%) skew(-45deg);
          }
          60% {
            transform: translateX(-10%) skew(-45deg);
          }
          80% {
            transform: translateX(-110%) skew(-45deg);
          }
          100% {
            transform: translateX(-110%) skew(-45deg);
          }
        }
        &:first-child {
          animation-delay: 0.15s;
        }
        &:nth-child(2) {
          animation-delay: 0.3s;
        }
        &:nth-child(3) {
          animation-delay: 0.45s;
        }
      }
    }
  }
  @include mobile {
    margin-bottom: s-md(125);
    &__container {
      flex-direction: column;
      border-radius: s-md(10);
      padding: 0;
      & > svg {
        @include block(100%, s-md(79));
      }
    }
    &__box {
      &--left,
      &--right {
        flex-basis: initial;
      }
      &--left {
        padding: s-md(26) s-md(16) 0;
        border-right: none;
        & svg {
          position: absolute;
          bottom: s(-84);
          left: s(-98);
          @include block(s(1608), s(182));
        }
      }
      &--right {
        padding: 0 s-md(16);
      }
    }
    &__title {
      margin-bottom: s-md(10);
    }
    &__desc {
      max-width: initial;
      font-size: s-md(18);
      line-height: 128%;
      letter-spacing: 0.035em;
    }
    &__form {
      padding: s-md(38) 0 0;
      &-desc {
        margin-bottom: s-md(28);
        margin-top: s-md(30);
        font-size: s-md(18);
        line-height: 128%;
        letter-spacing: 0.035em;
      }
      &-box {
        width: 100%;
        flex-direction: column;
        gap: s-md(18);
        &.active {
          padding-top: s-md(15);
        }
        & .contact__label {
          flex-basis: 100%;
        }
      }
    }
    &__additionally {
      font-size: s-md(14);
    }
    &__label {
      margin-bottom: s-md(15.5);
      &:last-of-type {
        margin-bottom: 5rem;
      }
    }
    &__input {
      padding: s-md(8) 0;
      border-width: 0 0 s-md(1) 0;
      &,
      &::placeholder {
        font-size: s-md(14);
        line-height: 140%;
        letter-spacing: 0.045em;
      }
      & + span {
        @include block(100%, s-md(2));
      }
    }
    &__select {
      & + .ui-selectmenu-button {
        max-width: initial;
        border-width: 0 0 s-md(1) 0;
        padding: s-md(4) 0 s-md(8) 0;
        &::after {
          @include block(100%, s-md(2));
        }
        & * {
          font-size: s-md(14);
          letter-spacing: 0.04em;
        }
      }
    }
    &__btn {
      margin-bottom: s-md(32);
      padding: s-md(15) s-md(24);
    }
  }
}

.ui-button .ui-icon {
  @include block(s(24));
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.546 7.93943C21.2647 7.65822 20.8832 7.50025 20.4855 7.50025C20.0877 7.50025 19.7063 7.65822 19.425 7.93943L12 15.3644L4.57499 7.93943C4.29209 7.66619 3.91318 7.515 3.51989 7.51842C3.12659 7.52183 2.75037 7.67959 2.47226 7.9577C2.19415 8.23581 2.0364 8.61203 2.03298 9.00532C2.02956 9.39862 2.18075 9.77752 2.45399 10.0604L10.9395 18.5459C11.2208 18.8271 11.6022 18.9851 12 18.9851C12.3977 18.9851 12.7792 18.8271 13.0605 18.5459L21.546 10.0604C21.8272 9.77914 21.9852 9.39767 21.9852 8.99993C21.9852 8.60218 21.8272 8.22072 21.546 7.93943Z' fill='%23EBEDF0'/%3E%3C/svg%3E%0A") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include transition(transform, rotateX(0));
  @include mobile {
    @include block(s-md(16));
    margin-top: s-md(2);
  }
}

.ui-selectmenu-button-open .ui-icon {
  transform: rotateX(180deg);
}

.ui-widget.ui-widget-content {
  border-color: rgba(255, 255, 255, 0.3);
}
.ui-widget-content {
  background-color: var(--grey);
  color: var(--white);
  & * {
    font-family: 'EuropeExt', sans-serif !important;
    text-transform: uppercase !important;
    font-size: s(16);
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.04em;
    @include mobile {
      font-size: s-md(14);
    }
  }
}

.ui-menu .ui-menu-item-wrapper {
  padding: s(10) 1em s(10) 0.4em;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border-color: #d33a45;
  background: #d33a45;
}
