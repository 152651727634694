.projects {
  &__title {
    &-box {
      @include flex-50-y();
      justify-content: space-between;
    }
  }
  &__slider {
    padding: s(112) s(2) s(40) 0;
    &-btn {
      & svg {
        @include block(s(20));
      }
      &-box {
        display: flex;
        gap: s(16);
        padding-bottom: s(9);
      }
      &--prev svg {
        transform: rotate(180deg);
      }
    }
  }
  &__slide {
    @include flex-50-50();
    cursor: pointer;
    height: s(260);
    border: s(1) solid var(--white);
    border-radius: s(10);
    background-color: transparent;
    transform: translateY(0);
    @include transitions((transform, background-color));
    &:hover {
      background-color: var(--red);
    }
    &.active {
      transform: translateY(-15%);
      background-color: var(--red);
    }
    &:nth-child(2) .projects__logo {
      width: 47%;
    }
    &:nth-child(3) .projects__logo {
      width: 84%;
    }
  }
  &__logo {
    width: 60%;
  }
  &__content {
    visibility: visible;
    opacity: 1;
    border-radius: s(10);
    padding: s(40) s(63) s(41) s(59);
    background-color: var(--red);
    &-box {
      display: none;
      align-items: center;
      justify-content: space-between;
      &.active {
        display: flex;
      }
    }
  }
  &__name {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    hyphens: auto;
    width: 100%;
    max-width: s(310);
    min-height: s(77);
    font-size: s(35);
    line-height: 110%;
  }
  &__desc {
    display: inline-flex;
    align-items: flex-start;
    flex-direction: column;
    text-transform: uppercase;
    width: 100%;
    max-width: s(310);
    font-family: 'GraphikLCG', sans-serif;
    font-weight: 300;
    font-size: s(18);
    line-height: 120%;
    & span {
      display: inline-block;
    }
    & .underline {
      position: relative;
      margin-top: s(5);
      @include text-bg('w');
      font-family: 'EuropeExt', sans-serif;
      font-weight: 700;
      font-size: s(14);
      line-height: 160%;
      letter-spacing: 0.04em;
      &::after {
        position: absolute;
        content: '';
        bottom: s(-3);
        left: 0;
        @include block(100%, s(2));
        background-color: var(--white);
      }
    }
  }
  &__progress {
    display: inline-flex;
    flex-direction: column;
    &--count {
      &-left,
      &-right {
        @include text-bg('w');
        font-weight: 700;
      }
      &-left {
        @include text-bg('w');
        font-size: s(35);
        line-height: 110%;
      }
      &-right {
        font-size: s(21);
      }
    }
    &--desc {
      font-family: 'GraphikLCG', sans-serif;
      font-size: s(20);
      font-weight: 300;
      line-height: 130%;
    }
  }
  @include mobile {
    padding-top: s-md(115);
    padding-bottom: s-md(76);
    &__container {
      padding: 0 s-md(14);
    }
    &__title {
      margin-bottom: s-md(38);
      &-box {
        align-items: flex-start;
        flex-direction: column;
        padding: 0 s-md(2) s-md(21.5);
      }
    }
    &__slider {
      height: s-md(358);
      padding: s-md(3) s-md(2);
      &-btn {
        & svg {
          @include block(s-md(20));
        }
        &-box {
          gap: s-md(16);
          padding-bottom: 0;
        }
      }
    }
    &__slide {
      height: calc((100% - s-md(30)) / 2);
      &.active {
        transform: translateY(0);
      }
    }
    &__content {
      border-radius: s-md(10);
      margin: s-md(16) s-md(2) 0 s-md(2);
      padding: s-md(18) s-md(37) s-md(7.5) s-md(16);
      &-box {
        align-items: flex-start;
        flex-direction: column;
      }
    }
    &__name {
      max-width: initial;
      font-size: s-md(22);
      margin-bottom: s-md(20);
      line-height: 130%;
    }
    &__desc {
      max-width: initial;
      margin-bottom: s-md(21);
      font-size: s-md(14);
      & .underline {
        margin-top: s-md(6);
        font-size: s-md(12);
        &::after {
          bottom: 0;
        }
      }
    }
    &__progress {
      flex-direction: row;
      align-items: flex-end;
      gap: s-md(14);
      margin-bottom: s-md(18);
      &--count {
        &-left {
          margin-right: s-md(-3);
          font-size: s-md(22);
          line-height: 150%;
        }
        &-right {
          font-size: s-md(17);
          line-height: 160%;
        }
      }
      &--desc {
        padding-bottom: s-md(4);
        font-size: s-md(16);
        letter-spacing: 0.04em;
      }
    }
  }
}
