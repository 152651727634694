.worth {
  position: relative;
  z-index: 5;
  &__title {
    margin-bottom: s(58);
    font-size: s(60);
    & .underline::after {
      bottom: s(2);
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(s(317), 1fr));
    gap: s(24);
  }
  &__item {
    position: relative;
    overflow: hidden;
    @include flex-50-x();
    min-height: s(205);
    border-radius: s(10);
    padding: s(35) s(25);
    &:nth-child(2) {
      padding: s(35) s(50);
    }
    &:nth-child(odd) {
      background-color: var(--red);
    }
    &:nth-child(even) {
      background-color: var(--grey);
    }
    & > span {
      position: absolute;
      top: 0;
      left: 0;
      @include block(100%);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  &__desc {
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;
    font-size: s(20);
    line-height: 155%;
    font-weight: 700;
    letter-spacing: 0.04em;
    @include text-bg('w');
  }
  @include mobile {
    padding-top: s-md(82);
    &__title {
      margin-bottom: s-md(30);
      font-size: s-md(29);
    }
    &__list {
      grid-template-columns: initial;
      gap: s-md(12);
    }
    &__item {
      justify-content: flex-start;
      min-height: s-md(60);
      border-radius: s-md(10);
      padding: s-md(20) s-md(16) s-md(18);
      &:nth-child(2) {
        padding: s-md(20) s-md(16) s-md(18);
      }
    }
    &__desc {
      text-align: left;
      font-size: s-md(14);
      line-height: 150%;
      letter-spacing: 0em;
    }
  }
}
