@mixin flex-50-y {
  display: flex;
  align-items: center;
}

@mixin flex-50-x {
  display: flex;
  justify-content: center;
}

@mixin flex-50-50 {
  display: flex;
  align-items: center;
  justify-content: center;
}
