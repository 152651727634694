.policy {
  overflow: hidden;
  padding-top: s(32);
  &__container {
    display: flex;
  }
  &__content {
    &--left {
      display: flex;
      flex-direction: column;
      margin-right: s(73);
    }
  }
  &__title {
    width: 100%;
    max-width: s(535);
    margin-bottom: s(38);
    font-size: s(60);
    & .underline::after {
      bottom: s(3);
    }
  }
  &__desc {
    width: 100%;
    max-width: s(513);
    font-family: 'GraphikLCG';
    font-weight: 400;
    font-size: s(22);
    line-height: 130%;
  }
  &__img {
    position: absolute;
    @include block(s(282));
    z-index: 2;
    background-color: #303033;
    border: s(1) solid var(--white);
    &-box {
      position: relative;
      @include block(s(489), s(564));
      min-width: s(489);
      margin-top: s(95);
      margin-right: s(181);
      &::before,
      &::after,
      & > svg,
      & > .icon,
      & > .logo {
        position: absolute;
        z-index: 3;
      }
      &::before,
      &::after {
        content: '';
        z-index: 1;
      }
      &::before {
        left: s(-1105);
        bottom: 0;
        @include block(s(1355), s(311));
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.03) 37.97%, rgba(255, 255, 255, 0) 91.6%);
      }
      &:after {
        top: s(3);
        right: s(-995);
        @include block(s(1242), s(336));
        background: linear-gradient(93.47deg, rgba(155, 50, 46, 0.12) 27.09%, rgba(157, 22, 46, 0.12) 27.09%, rgba(229, 85, 79, 0) 85.93%);
      }
      & > svg {
        bottom: 0;
        left: -89.5rem;
        @include block(s(755), s(214));
      }
      & .icon {
        @include block(s(20));
        background-color: var(--white);
        &,
        &::before {
          border-radius: 100%;
        }
        &::before,
        &::after {
          position: absolute;
          content: '';
        }
        &::before {
          top: 50%;
          left: 50%;
          @include block(s(11));
          background-color: #e5554f;
          box-shadow: s(2) s(3) s(8) #e17a7a;
          transform: translate(-50%, -50%);
        }
        &::after {
          background-size: cover;
          background-repeat: no-repeat;
        }
        &-1 {
          top: s(67);
          right: s(142);
          &::after {
            top: s(-41);
            right: s(-320);
            @include block(s(331), s(39));
            background-image: url("data:image/svg+xml,%3Csvg width='332' height='40' viewBox='0 0 332 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.00001 40V1L332 1' stroke='url(%23paint0_linear_1003_26347)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1003_26347' x1='-11.0871' y1='2.41139e+08' x2='542.129' y2='2.41139e+08' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='0.836458' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
          }
        }
        &-2 {
          left: s(100);
          bottom: s(90);
          &::after {
            top: s(20);
            left: s(9);
            @include block(s(559), s(123));
            background-image: url("data:image/svg+xml,%3Csvg width='560' height='124' viewBox='0 0 560 124' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.00002 2.74181e-06V123L560 123' stroke='url(%23paint0_linear_1003_26346)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1003_26346' x1='-19.413' y1='-7.60516e+08' x2='914.871' y2='-7.60516e+08' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='0.836458' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
          }
        }
        &-3 {
          right: s(15);
          bottom: s(262);
          &::after {
            top: s(8);
            right: s(-195);
            @include block(s(195), s(2));
            background-image: url("data:image/svg+xml,%3Csvg width='195' height='2' viewBox='0 0 195 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-3.39746e-06 1L195 1' stroke='url(%23paint0_linear_1003_26351)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1003_26351' x1='-7.1208' y1='-6.18306e+06' x2='318.792' y2='-6.18306e+06' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='0.836458' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
          }
        }
      }
      & .logo {
        &-1 {
          top: s(32);
          left: s(55);
          @include img(s(380));
        }
        &-2 {
          bottom: s(49);
          left: s(13);
          @include img(s(220));
        }
        &-3 {
          bottom: s(60);
          right: s(34);
          @include img(s(180));
        }
      }
    }
    &--left,
    &--right {
      bottom: s(71);
    }
    &--top {
      top: 0;
      left: s(102);
      transform: matrix(0.87, 0.5, -0.87, 0.5, 0, 0);
    }
    &--left {
      left: s(-20);
      transform: matrix(0.87, 0.5, 0, 1, 0, 0);
    }
    &--right {
      right: s(-18);
      transform: matrix(0.87, -0.5, 0, 1, 0, 0);
    }
  }
  &__list {
    position: relative;
    padding-top: s(10);
    z-index: 2;
  }
  &__item {
    border-radius: s(10);
    padding: s(30);
    background-color: var(--grey);
    &:not(:last-child) {
      margin-bottom: s(70);
    }
    &:first-child {
      padding: s(41) s(30);
    }
    &-title {
      text-transform: uppercase;
      font-size: s(18);
      line-height: 160%;
      letter-spacing: 0.04em;
      @include text-bg('w');
    }
    &-desc {
      margin-top: s(5);
      font-family: 'GraphikLCG';
      font-weight: 400;
      font-size: s(20);
      line-height: 130%;
      color: rgba(255, 255, 255, 0.5);
      & > a {
        text-decoration: underline;
        @include transition(color);
        &:hover,
        &:active {
          color: var(--red);
        }
      }
    }
  }
  @include mobile {
    padding-top: s-md(114);
    &__container {
      flex-direction: column;
    }
    &__content--left {
      margin-right: 0;
      margin-bottom: s-md(30);
    }
    &__title {
      margin-bottom: s-md(22);
      letter-spacing: -0.03em;
    }
    &__desc {
      max-width: initial;
      font-size: s-md(18);
      @include text-bg('w');
      letter-spacing: 0.02em;
    }
    &__img {
      @include block(s-md(142.8));
      &-box {
        counter-reset: number;
        margin: 0 auto s-md(32);
        &::before {
          left: s-md(-179);
          bottom: s-md(-4);
          @include block(s-md(302), s-md(133));
        }
        &:after {
          top: 0;
          right: s-md(-181);
          @include block(s-md(304), s-md(144));
        }
        & > svg {
          bottom: s-md(1);
          left: -69.5rem;
          height: 19.4rem;
        }
        & .logo {
          &-2 {
            bottom: s-md(22);
          }
          &-3 {
            bottom: s-md(28);
          }
        }
        & .icon {
          background-color: transparent;
          &::before {
            counter-increment: number;
            content: counter(number, decimal-leading-zero);
            text-transform: uppercase;
            @include block(initial);
            font-size: s-md(14);
            font-weight: 700;
            line-height: 150%;
            box-shadow: none;
            background-color: transparent;
          }
          &::after {
            content: none;
          }
          &-1 {
            top: s-md(5);
            right: s-md(125);
            &::before {
              transform: matrix(0.87, 0.5, -0.87, 0.5, 0, 0);
            }
          }
          &-2 {
            left: s-md(1);
            bottom: s-md(183);
            &::before {
              transform: matrix(0.87, 0.5, 0, 1, 0, 0);
            }
          }
          &-3 {
            right: s-md(24);
            bottom: s-md(187);
            &::before {
              transform: matrix(0.87, -0.5, 0, 1, 0, 0);
            }
          }
        }
      }
      &--left,
      &--right {
        bottom: s-md(33);
      }
      &--left {
        left: s-md(-10.5);
      }
      &--right {
        right: s-md(-12);
      }
    }
    &__list {
      display: flex;
      flex-direction: column;
      counter-reset: number;
    }
    &__item {
      display: flex;
      padding: s-md(14) s-md(16) s-md(16) !important;
      border-radius: s-md(10);
      &:not(:last-child) {
        margin-bottom: s-md(6);
      }
      &::before {
        position: relative;
        counter-increment: number;
        content: counter(number, decimal-leading-zero);
        text-transform: uppercase;
        top: s-md(-1);
        margin-right: s-md(12);
        font-size: s-md(20);
        font-weight: 700;
        line-height: 150%;
        color: var(--red);
      }
      &-title {
        font-size: s-md(14);
        line-height: 150%;
        letter-spacing: 0;
      }
      &-desc {
        margin-top: s-md(6);
        font-size: s-md(16);
        line-height: 130%;
        letter-spacing: 0.016em;
      }
    }
  }
}
