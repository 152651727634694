.vacancies {
  padding-top: s(184);
  padding-bottom: s(203);
  &__title {
    font-size: s(60);
    & .underline::after {
      bottom: s(3);
    }
    &-box {
      display: flex;
      justify-content: space-between;
      padding-bottom: s(59);
    }
  }
  &__slider {
    &-btn {
      &-box {
        display: flex;
        align-items: flex-end;
        gap: s(5);
        margin-bottom: s(3);
      }
      & svg {
        @include block(s(22));
      }
      &--prev svg {
        transform: rotate(180deg);
      }
    }
  }
  &__slide {
    height: auto;
    border: s(1) solid var(--white);
    border-radius: s(10);
    padding: s(30) s(25);
    & .frame {
      z-index: 2;
    }
    &:hover,
    &:active {
      & .vacancies__link {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__logo {
    @include img(s(70), s(70));
    margin-right: s(20);
    &-box {
      @include flex-50-y();
      padding-bottom: s(28);
    }
  }
  &__site {
    display: flex;
    flex-direction: column;
    font-family: 'GraphikLCG';
    font-weight: 400;
    font-size: s(20);
    line-height: 130%;
    & > span {
      &:first-child {
        margin-bottom: s(5);
      }
      &:last-child {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  &__desc {
    text-transform: uppercase;
    line-height: 160%;
    letter-spacing: 0.04em;
    @include text-ellipsis(2);
    @include text-bg('w');
  }
  &__link {
    @include flex-50-50();
    flex-direction: column;
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    @include block(100%);
    border-radius: s(10);
    background-color: var(--red);
    @include transitions((visibility, opacity));
    &-icon {
      @include flex-50-50();
      @include block(s(53));
      margin-bottom: s(15);
      border: s(0.5) solid var(--white);
      border-radius: 100%;
      & svg {
        @include block(s(18));
      }
    }
    &-desc {
      text-align: center;
      text-transform: uppercase;
      font-size: s(14);
      font-weight: 400;
      line-height: s(17);
    }
  }
  @include mobile {
    position: relative;
    margin-bottom: s-md(119);
    padding-top: s-md(114);
    padding-bottom: s-md(84);
    padding-right: 0;
    &__title {
      letter-spacing: -0.03em;
      &-box {
        padding-left: 0;
        padding-bottom: s-md(28);
      }
    }
    &__slider {
      padding: s-md(2) 0;
      &-btn {
        &-box {
          position: absolute;
          z-index: 5;
          left: s-md(16);
          bottom: 0;
          gap: s-md(16);
          margin-bottom: 0;
        }
        & > svg {
          @include block(s-md(20));
        }
      }
    }
    &__slide {
      width: 100%;
      max-width: s-md(300);
      border-radius: s-md(10);
      padding: s-md(25) s-md(16);
    }
    &__logo {
      @include block(s-md(54));
      margin-right: s-md(11);
      &-box {
        padding-bottom: s-md(23);
      }
    }
    &__site {
      margin-top: s-md(4);
      font-size: s-md(16);
      line-height: 130%;
      letter-spacing: 0.02em;
      & > span:first-child {
        margin-bottom: s-md(4);
      }
    }
    &__desc {
      font-size: s-md(12);
      line-height: 140%;
    }
  }
}
