*,
*::before,
*::after {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

@if $reset == true {
  button,
  input,
  textarea,
  select {
    margin: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
  }

  a,
  button,
  select,
  input,
  textarea {
    cursor: pointer;
    font: inherit;
    line-height: inherit;
    color: inherit;
    &:focus {
      outline: 0;
    }
  }

  strong {
    font: inherit;
    line-height: inherit;
  }

  a,
  button {
    display: inline-block;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font: inherit;
  }

  p {
    margin: 0;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  textarea {
    resize: none; // Запрещаем изменять размер
  }

  // Отключение стрелок в input с типом "number"
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  // Отключение кнопки для очистки в input с типом "text"
  input[type='text']::-ms-clear,
  input[type='text']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  // Отключение иконки поиска в input с типом "search"
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}
