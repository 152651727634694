@mixin block($width: none, $height: none) {
  @if $width != none {
    @if $height == none {
      width: $width;
      height: $width;
    } @else {
      width: $width;
      height: $height;
    }
  }
}
