$base-url: '../fonts/';

@mixin font($file-name, $font-family, $font-weight, $font-style: 'normal', $ext: none, $format: none) {
  @font-face {
    font-family: $font-family;
    font-display: swap;
    font-weight: $font-weight;
    font-style: $font-style;
    @if $ext != none {
      src: url($base-url + $file-name + '.#{$ext}') format($format);
    } @else {
      src: url($base-url + $file-name + '.woff2') format('woff2'), url($base-url + $file-name + '.woff') format('woff');
    }
  }
}
