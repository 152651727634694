.header {
  position: relative;
  z-index: 61;
  padding-top: s(32);
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    @include block(s(330), s(55));
    & svg {
      @include block(inherit);
    }
  }
  &__tel {
    margin-right: s(-115);
    letter-spacing: 0.065em;
  }
  &__lang {
    position: relative;
    text-transform: uppercase;
    font-size: s(16);
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.04em;
    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      @include block(100%, s(4));
      background-color: var(--red);
      @include transition(opacity, 0);
    }
    &.active::after,
    &:hover::after,
    &:active::after {
      opacity: 1;
    }
    &-box {
      @include flex-50-y();
      gap: s(15);
      &.mobile {
        display: none;
      }
    }
  }
  @include mobile {
    padding-top: s-md(30);
    &__logo {
      @include block(s-md(238), s-md(38));
      margin-left: s-md(-8);
    }
    &__burger {
      @include flex-50-50();
      @include block(s-md(53));
      border-radius: 100%;
      border: s-md(0.5) solid var(--white);
      & svg {
        @include block(s-md(25), s-md(18));
      }
    }
    &__lang {
      font-size: s-md(14);
      line-height: 150%;
      &-box {
        gap: s-md(13);
        margin-bottom: s-md(10);
        &.mobile {
          display: flex;
        }
        &.desktop {
          display: none;
        }
      }
    }
  }
}

.nav {
  margin-right: s(-30);
  padding: s(12) s(26) s(12) s(28);
  border: s(1) solid var(--white);
  border-radius: s(39);
  &__list {
    display: flex;
    align-items: center;
    gap: s(40);
  }
  &__link {
    text-transform: uppercase;
    letter-spacing: 0.04em;
  }
  @include mobile {
    position: absolute;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    visibility: hidden;
    overflow: hidden;
    top: s-md(100);
    left: 0;
    opacity: 0;
    z-index: 50;
    @include block(100%, auto);
    padding: s-md(39) s-md(16) s-md(49);
    border: none;
    border-radius: 0;
    background-color: var(--grey-shadow);
    @include transitions((visibility, opacity));
    &.active {
      visibility: visible;
      opacity: 1;
    }
    &__list {
      align-items: flex-start;
      flex-direction: column;
      gap: s-md(32);
      padding-bottom: s-md(43.5);
    }
    &__link {
      font-size: s-md(26);
      line-height: 130%;
      letter-spacing: 0;
    }
    &__tel {
      display: inline-block;
      text-transform: uppercase;
      margin-bottom: s-md(39);
      border: s-md(1) solid var(--white);
      border-radius: s-md(1000);
      padding: s-md(14.5) s-md(24);
      font-size: s-md(13);
      font-weight: 700;
      line-height: s-md(12);
      letter-spacing: 0.04em;
    }
    &__img {
      position: relative;
      right: s-md(-55);
      z-index: 3;
      @include img(s-md(346), s-md(248));
      &-box {
        position: relative;
        &::before,
        &::after {
          position: absolute;
          content: '';
          z-index: 1;
        }
        &::before {
          bottom: s-md(31);
          left: -5.2rem;
          @include block(s-md(265), s-md(105));
          background: linear-gradient(270deg, rgba(255, 255, 255, 0.03) 37.97%, rgba(255, 255, 255, 0) 91.6%);
        }
        &::after {
          top: 6.2rem;
          right: s-md(-74);
          @include block(s-md(179), s-md(131));
          background: linear-gradient(93.47deg, rgba(155, 50, 46, 0.12) 27.09%, rgba(157, 22, 46, 0.12) 27.09%, rgba(229, 85, 79, 0) 85.93%);
        }
        & > svg {
          position: absolute;
          left: -71.5rem;
          bottom: -1.7rem;
          z-index: 2;
          @include block(s-md(1050), 48rem);
        }
      }
    }
  }
}
