// Изменить цвет курсора
// input,
// textarea {
//   caret-color: var(--black);
// }

// Запрет на выделение контента
// body {
//   user-select: none;
// }

.us-title {
  text-transform: uppercase;
  font-weight: 400;
  font-size: s(82);
  line-height: 110%;
  color: var(--white);
  & .underline {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: s(8);
      @include block(100%, s(2));
      background-color: var(--white);
    }
  }
  @include mobile {
    font-size: s-md(29);
    & .underline {
      &::after {
        bottom: s-md(1);
        @include block(100%, s-md(1));
      }
    }
  }
}

.us-btn {
  text-transform: uppercase;
  border: s(1) solid var(--white);
  border-radius: s(1000);
  padding: s(14) s(23);
  letter-spacing: 0.04em;
  color: var(--white);
  @include mobile {
    padding: s-md(15) s-md(24);
    font-size: s-md(13);
    line-height: s-md(12);
  }
}

.us-btn-slider {
  @include flex-50-50();
  @include block(s(53));
  border: s(0.5) solid var(--white);
  border-radius: 100%;
  @include transition(opacity, 1);
  &.swiper-button-disabled {
    opacity: 0.4;
  }
  &--prev svg {
    transform: rotate(180deg);
  }
  @include mobile {
    @include block(s-md(53));
    border-width: s-md(0.5);
  }
}

.us-link {
  transform: scale(1);
  color: var(--white);
  @include transitions((transform, color));
  &:hover,
  &:active {
    transform: scale(0.98);
    color: var(--red);
  }
}

.slogan {
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  font-weight: 700;
  font-size: s(190);
  line-height: 110%;
  color: rgba(255, 255, 255, 0.02);
  @include mobile {
    font-size: s-md(100);
  }
}

.frame {
  position: absolute;
  @include block(s(22));
  border-color: var(--white);
  border-style: solid;
  &-1,
  &-2 {
    top: s(-1);
  }
  &-1,
  &-3 {
    left: s(-1);
  }
  &-2,
  &-4 {
    right: s(-1);
  }
  &-3,
  &-4 {
    bottom: s(-1);
  }
  &-1 {
    border-width: s(3) 0 0 s(3);
    border-top-left-radius: s(9.5);
  }
  &-2 {
    border-width: s(3) s(3) 0 0;
    border-top-right-radius: s(9.5);
  }
  &-3 {
    border-width: 0 0 s(3) s(3);
    border-bottom-left-radius: s(9.5);
  }
  &-4 {
    border-width: 0 s(3) s(3) 0;
    border-bottom-right-radius: s(9.5);
  }
  @include mobile {
    @include block(s-md(19.7));
    &-1,
    &-2 {
      top: s-md(-2);
    }
    &-1,
    &-3 {
      left: s-md(-2);
    }
    &-2,
    &-4 {
      right: s-md(-2);
    }
    &-3,
    &-4 {
      bottom: s-md(-2);
    }
    &-1 {
      border-width: s-md(3) 0 0 s-md(3);
      border-top-left-radius: s-md(9.5);
    }
    &-2 {
      border-width: s-md(3) s-md(3) 0 0;
      border-top-right-radius: s-md(9.5);
    }
    &-3 {
      border-width: 0 0 s-md(3) s-md(3);
      border-bottom-left-radius: s-md(9.5);
    }
    &-4 {
      border-width: 0 s-md(3) s-md(3) 0;
      border-bottom-right-radius: s-md(9.5);
    }
  }
}
