.smartmoney {
  position: relative;
  padding-top: s(95);
  padding-bottom: s(185);
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 18rem;
    z-index: 4;
    @include block(s(674), s(1143));
    background: linear-gradient(180deg, rgba(35, 35, 37, 0) 0%, #232325 77.96%);
  }
  & > svg {
    position: absolute;
    top: s(87);
    right: 0;
    @include block(s(1155), s(500));
  }
  &__container {
    display: flex;
  }
  &__img {
    position: relative;
    top: s(-38);
    z-index: 5;
    @include block(s(457), s(531));
    &-box {
      position: relative;
      padding-left: s(38);
      margin-right: s(80);
      &::before,
      &::after {
        position: absolute;
        content: '';
        background-repeat: no-repeat;
        background-size: cover;
      }
      &::before {
        top: s(96);
        left: s(-117);
        @include block(s(373), s(391));
        z-index: 2;
        background-image: url("data:image/svg+xml,%3Csvg class='rectangle-39906' width='373' height='391' viewBox='0 0 373 391' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M0 0L373 0V391H0V0Z' fill='url(%23paint0_linear_1280_11326)' fill-opacity='0.03' /%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1280_11326' x1='241.693' y1='164.401' x2='32.7117' y2='164.401' gradientUnits='userSpaceOnUse' %3E%3Cstop stop-color='white' /%3E%3Cstop offset='1' stop-color='white' stop-opacity='0' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
      }
      &::after {
        top: s(-34);
        right: s(-1295);
        @include block(s(1505), s(367));
        z-index: 2;
        background-image: url("data:image/svg+xml,%3Csvg class='rectangle-39907' width='1507' height='367' viewBox='0 0 1507 367' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M0 0H1507V367H0V0Z' fill='url(%23paint0_linear_1280_11327)' fill-opacity='0.12' /%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1280_11327' x1='178.42' y1='256.679' x2='1427.23' y2='572.306' gradientUnits='userSpaceOnUse' %3E%3Cstop stop-color='%239B322E' /%3E%3Cstop offset='1' stop-color='%23E5554F' stop-opacity='0' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
      }
    }
    &--shadow {
      position: absolute;
      bottom: s(-290);
      right: s(65);
      @include block(s(395), s(348));
    }
  }
  &__title {
    text-transform: uppercase;
    margin-bottom: s(86);
    font-size: s(82);
    font-weight: 700;
    line-height: 110%;
    color: var(--red);
  }
  &__desc {
    text-align: justify;
    width: 100%;
    max-width: s(885);
    padding-left: s(79);
    font-family: 'GraphikLCG', sans-serif;
    font-size: s(28);
    font-weight: 400;
    line-height: 160%;
    & strong {
      font-weight: 600;
    }
  }
  @include mobile {
    overflow: hidden;
    padding-top: s-md(135);
    padding-bottom: s-md(116);
    &::before {
      @include block(100%, s-md(736));
      top: s-md(-7);
      left: s-md(-35);
    }
    & > svg {
      top: 28.3rem;
      right: s-md(-337);
      @include block(s-md(1050), s-md(260));
    }
    &__container {
      flex-direction: column;
    }
    &__img {
      top: s-md(-20);
      @include block(s-md(257), s-md(299));
      &-box {
        padding-left: s-md(36);
        &::before {
          top: 11.6rem;
          left: -40rem;
          @include block(s-md(357), s-md(217));
          background-image: url("data:image/svg+xml,%3Csvg width='214' height='217' viewBox='0 0 214 217' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-143 0H214L198.755 217H-143V0Z' fill='url(%23paint0_linear_1317_11702)' fill-opacity='0.03'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1317_11702' x1='78.4471' y1='91.2405' x2='-113.028' y2='91.2405' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        }
        &::after {
          top: -3.8rem;
          right: -24rem;
          @include block(s-md(241), s-md(207));
          background-image: url("data:image/svg+xml,%3Csvg width='180' height='207' viewBox='0 0 180 207' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H241V207H0V0Z' fill='url(%23paint0_linear_1317_11703)' fill-opacity='0.12'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1317_11703' x1='28.533' y1='144.776' x2='179.55' y2='156.756' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.146298' stop-color='%239B322E'/%3E%3Cstop offset='1' stop-color='%23E5554F' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        }
      }
      &--shadow {
        top: 53rem;
        right: 2.5rem;
        bottom: initial;
        @include block(s-md(286), s-md(252));
      }
    }
    &__title {
      position: absolute;
      top: s-md(56);
      left: s-md(16);
      font-size: s-md(29);
    }
    &__desc {
      text-align: left;
      position: relative;
      padding-top: s-md(23);
      z-index: 5;
      font-size: s-md(20);
      padding-left: 0;
      @include text-bg('w');
      & strong {
        font-family: 'EuropeExt';
        font-weight: 700;
        letter-spacing: 0.04em;
      }
      & span.mobile {
        font-size: 5rem;
      }
    }
  }
}
