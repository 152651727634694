:root {
  --black: #000;
  --white: #fff;
  --red: #e5554f;
  --grey: #434347;
  --grey-shadow: #232325;
}

// Тёмная тема
@media (prefers-color-scheme: dark) {
}

// Светлая тема
@media (prefers-color-scheme: light) {
}
