.about-main {
  position: relative;
  margin-top: s(75);
  &::before {
    position: absolute;
    content: '';
    top: s(-160);
    left: 18rem;
    z-index: 3;
    @include block(s(674), s(1078));
    background: linear-gradient(180deg, rgba(35, 35, 37, 0) 0%, #232325 77.96%);
  }
  & svg {
    position: absolute;
    right: s(-130);
    top: s(55);
    @include block(s(1173), s(525));
  }
  &__container {
    position: relative;
    display: flex;
    overflow: hidden;
    padding-left: 16.6rem;
    padding-bottom: s(180);
  }
  &__title {
    margin-bottom: s(58);
    font-size: s(82);
    font-weight: 700;
    color: var(--red);
  }
  &__slogan {
    bottom: s(-40);
    right: s(-78);
  }
  &__img {
    position: relative;
    z-index: 5;
    top: s(-25);
    margin-left: s(120);
    @include img(s(473), s(530));
    &-box {
      position: relative;
      &::before,
      &::after {
        position: absolute;
        content: '';
      }
      &::before {
        top: s(280);
        left: s(-175);
        z-index: 4;
        @include block(s(535), s(204));
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0) 100%);
      }
      &::after {
        top: s(20);
        right: s(-1337);
        @include block(s(1577), s(260));
        background-image: url("data:image/svg+xml,%3Csvg width='1571' height='260' viewBox='0 0 1571 260' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H1577V260H0V0Z' fill='url(%23paint0_linear_1204_18179)' fill-opacity='0.12'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1204_18179' x1='186.707' y1='181.844' x2='1406.93' y2='637.387' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%239B322E'/%3E%3Cstop offset='1' stop-color='%23E5554F' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &--shadow {
      position: absolute;
      top: s(510);
      left: s(135);
      z-index: 1;
      @include img(s(433), s(485));
    }
  }
  &__content {
    position: relative;
    z-index: 5;
    max-width: 1173px;
    padding-left: s(60);
    padding-top: s(48);
  }
  &__desc {
    text-align: justify;
    width: 100%;
    max-width: s(860);
    font-family: 'GraphikLCG';
    font-weight: 300;
    font-size: s(22);
    line-height: 130%;
    & strong {
      font-weight: 600;
    }
  }
  @include mobile {
    margin-top: 0;
    &::before {
      top: s-md(110);
      left: s-md(44);
      @include block(s-md(287), s-md(459));
      background: linear-gradient(180deg, rgba(35, 35, 37, 0) 0%, #232325 77.96%);
    }
    & svg {
      top: 28.3rem;
      right: -50.4rem;
      z-index: 4;
      @include block(s-md(849), s-md(211));
    }
    &__container {
      padding-top: s-md(95);
      padding-bottom: s-md(32.5);
      padding-left: 0;
    }
    &__title {
      position: absolute;
      top: -68rem;
      font-size: s-md(29);
    }
    &__img {
      width: 50.1rem;
      height: 56.1rem;
      top: 0;
      &-box {
        &::before {
          top: 33rem;
          left: -27.5rem;
          @include block(s-md(322), s-md(104));
        }
        &::after {
          top: 6.5rem;
          right: s-md(-25);
          @include block(s-md(217), s-md(135));
          background-image: url("data:image/svg+xml,%3Csvg width='187' height='135' viewBox='0 0 187 135' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Rectangle 39907' d='M0 0H217V135H0V0Z' fill='url(%23paint0_linear_1204_18204)' fill-opacity='0.12'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1204_18204' x1='25.6915' y1='94.4189' x2='160.903' y2='109.229' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.146298' stop-color='%239B322E'/%3E%3Cstop offset='1' stop-color='%23E5554F' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        }
      }
    }
    &__container {
      flex-direction: column;
    }
    &__content {
      padding: s-md(37) s-md(16) 0;
    }
    &__desc {
      text-align: left;
      font-size: s-md(18);
      line-height: 128%;
      letter-spacing: 0.034em;
    }
  }
}
