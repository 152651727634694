.hero {
  position: relative;
  overflow: hidden;
  padding-bottom: s(170);
  &::before {
    position: absolute;
    content: '';
    top: s(-87);
    right: s(330);
    z-index: 1;
    @include block(s(1024), s(1564));
    background: linear-gradient(180deg, rgba(35, 35, 37, 0) 0%, #232325 77.96%);
  }
  &__video {
    position: fixed;
    visibility: visible;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    z-index: 1000;
    @include block(100vw, 100vh);
    object-fit: cover;
    object-position: 50% 20%;
    @include transitions((visibility, opacity));
    &.hidden {
      visibility: hidden;
      opacity: 0;
    }
  }
  &__title {
    margin-left: s(-5);
    & .underline::after {
      bottom: s(13);
    }
  }
  &__content {
    &--top {
      padding-top: s(134);
      &.active {
        & .hero {
          &__title,
          &__desc,
          &__btn {
            opacity: 1;
            transform: translateX(0);
          }
          &__img-box {
            &::before,
            &::after {
              opacity: 1;
            }
            & svg .svg-elem {
              stroke-dashoffset: 0;
            }
          }
          &__btn {
            transition-duration: 0.4s;
            transition-delay: 0s;
          }
        }
      }
    }
    &--bottom {
      position: relative;
      z-index: 5;
      margin-left: s(-10);
      padding-top: s(164);
    }
  }
  &__title {
    position: relative;
    z-index: 5;
    opacity: 0;
    width: 100%;
    max-width: s(847);
    margin-bottom: s(160);
    transform: translateX(-100%);
    @include transition((transform, opacity), none, 0.8s);
    transition-delay: 0.1s;
    &--red {
      font-weight: 700;
      color: var(--red);
    }
  }
  &__img {
    position: relative;
    z-index: 10;
    @include img(s(1283), s(894));
    &-box {
      position: absolute;
      right: 0;
      top: s(10);
      &::before,
      &::after {
        position: absolute;
        content: '';
        transition-delay: 0.5s;
      }
      &::before {
        bottom: s(98);
        left: s(-660);
        @include block(s(1358), s(382));
        z-index: 2;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.03) 37.97%, rgba(255, 255, 255, 0) 91.6%);
        @include transition(opacity, 0);
      }
      &::after {
        top: s(177);
        right: s(-245);
        @include block(s(869), s(445));
        z-index: 2;
        background: linear-gradient(93.47deg, rgba(155, 50, 46, 0.12) 27.09%, rgba(157, 22, 46, 0.12) 27.09%, rgba(229, 85, 79, 0) 85.93%);
        @include transition(opacity, 0);
      }
      & svg {
        position: absolute;
        left: s(-470);
        bottom: s(54);
        z-index: 1;
        @include img(s(1752), s(425));
        & .svg-elem {
          &-1 {
            stroke-dashoffset: 2837.826904296875px;
            stroke-dasharray: 2837.826904296875px;
            transition: stroke-dashoffset 1.5s ease-in 1s;
          }
          &-2 {
            stroke-dashoffset: 2837.489013671875px;
            stroke-dasharray: 2837.489013671875px;
            transition: stroke-dashoffset 1.5s ease-in 1.12s;
          }
          &-3 {
            stroke-dashoffset: 2865.79736328125px;
            stroke-dasharray: 2865.79736328125px;
            transition: stroke-dashoffset 1.5s ease-in 1.24s;
          }
        }
      }
    }
    &--shadow {
      position: absolute;
      bottom: s(-515);
      right: s(325);
      @include block(s(652), s(574));
    }
  }
  &__tabs {
    position: absolute;
    top: s(324);
    right: s(612);
    z-index: 10;
  }
  &__tab {
    position: absolute;
    &-1 {
      top: s(-46);
      left: s(-230);
    }
    &-2 {
      top: s(-184);
      right: s(-73);
    }
    &-3 {
      bottom: s(-179);
      left: s(-71);
    }
    &-4 {
      top: s(-5);
      right: s(-246);
    }
    & > span {
      cursor: pointer;
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      z-index: -1;
      @include block(s(33));
      border-radius: 100%;
      border: s(1) solid var(--white);
      background-color: rgba(229, 85, 79, 0.5);
      transform: translate(-50%, -50%);
      @include transition(opacity, 0);
    }
    &-btn {
      position: relative;
      @include flex-50-50();
      @include block(s(22));
      z-index: 2;
      background-color: var(--white);
      @include transition(transform, scale(1));
      &,
      & span {
        border-radius: 100%;
      }
      & span {
        display: inline-block;
        @include block(s(11));
        background-color: var(--red);
        box-shadow: s(2) s(3) s(8) #e17a7a;
      }
    }
    &:hover,
    &:active {
      & > span {
        opacity: 1;
      }
      & .hero__tab-btn {
        transform: scale(0.85);
      }
      & .hero__tab-desc {
        visibility: visible;
        opacity: 1;
      }
    }
    &-desc {
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      visibility: hidden;
      top: s(-115);
      left: 50%;
      opacity: 0;
      border-radius: s(757);
      padding: s(20) s(41);
      font-size: s(18);
      line-height: 160%;
      letter-spacing: 0.04em;
      background-color: rgba(255, 255, 255, 0.4);
      box-shadow: inset 0 s(3) s(8.3) rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(s(7.5));
      transform: translateX(-50%);
      @include transitions((opacity, visibility));
    }
  }
  &__slogan {
    bottom: s(27);
    right: s(-78);
  }
  &__desc {
    position: relative;
    text-transform: uppercase;
    z-index: 5;
    opacity: 0;
    width: 100%;
    max-width: s(431);
    margin-bottom: s(50);
    font-size: s(18);
    line-height: 155%;
    letter-spacing: 0.05em;
    transform: translateX(-100%);
    @include transition((transform, opacity), none, 0.8s);
    transition-delay: 0.5s;
  }
  &__btn {
    position: relative;
    z-index: 5;
    opacity: 0;
    transform: translateX(-100%);
    @include transitions((transform, opacity, background-color, border-color), none, 0.8s);
    transition-delay: 1s;
    &:hover,
    &:active {
      border-color: var(--red);
      background-color: var(--red);
    }
  }
  &__slider-wrapper {
    gap: s(185);
  }
  &__slide {
    width: auto;
    &:first-child {
      & .hero__progress {
        &--count {
          margin-right: 1.3rem;
        }
        &--desc {
          max-width: s(117);
        }
      }
    }
    &:nth-child(2) .hero__progress--desc {
      max-width: s(115);
    }
    &:nth-child(3) .hero__progress--desc {
      max-width: s(171);
    }
    &:nth-child(4) .hero__progress--desc {
      max-width: s(147);
    }
  }
  &__progress {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    &--count,
    &--desc {
      display: inline-block;
    }
    &--count {
      margin-right: s(26);
      font-size: s(70);
      font-weight: 400;
      line-height: s(77);
      @include text-bg();
    }
    &--desc {
      width: 100%;
      padding-top: s(9);
      letter-spacing: 0.05em;
    }
  }
  @include mobile {
    padding-bottom: 15.5rem;
    &::before {
      top: s-md(101.5);
      right: s-md(30);
      @include block(s-md(375), s-md(686));
    }
    &__video {
      display: none;
    }
    &__title,
    &__desc,
    &__btn {
      opacity: 1;
      transform: translateX(0);
    }
    &__container {
      padding-right: 0;
    }
    &__content {
      &--top {
        padding-top: s-md(56);
      }
      &--bottom {
        margin-left: 0;
        padding-top: s-md(11);
      }
    }
    &__title {
      margin-left: 0;
      & .underline::after {
        bottom: s-md(1);
      }
    }
    &__img {
      @include block(s-md(534), s-md(334));
      left: s-md(-118);
      top: s-md(-57);
      &-box {
        position: relative;
        & > svg {
          @include block(s-md(1050), s-md(257));
          left: s-md(-352);
          bottom: s-md(66);
        }
        &::before {
          top: s-md(95);
          left: s-md(-150);
          @include block(s-md(357), s-md(142));
        }
        &::after {
          top: s-md(-17);
          right: s-md(-65);
          @include block(s-md(241), s-md(177));
        }
        &::before,
        &::after {
          opacity: 1;
        }
        & svg .svg-elem {
          stroke-dashoffset: 0;
        }
      }
      &--shadow {
        bottom: s-md(-187.5);
        right: s-md(70);
        @include block(s-md(286), s-md(251.75));
      }
    }
    &__tabs {
      top: s-md(69.5);
      right: s-md(208);
    }
    &__tab {
      & > span {
        top: 51%;
        @include block(s-md(12.7));
        border-width: s-md(1);
      }
      &:hover,
      &:active {
        & .hero__tab-btn {
          transform: scale(0.78);
        }
      }
      &-1 {
        top: s-md(-31);
        left: s-md(-84.5);
      }
      &-2 {
        top: s-md(-90);
        right: s-md(-38);
      }
      &-3 {
        bottom: s-md(-63);
        left: s-md(-17);
      }
      &-4 {
        top: s-md(-12.5);
        right: s-md(-112.5);
      }
      &-btn {
        @include block(s-md(9.3));
        & span {
          @include block(s-md(4.65));
          box-shadow: s-md(2) s-md(3) s-md(8) #e17a7a;
        }
      }
      &-desc {
        top: s-md(-64.5);
        font-size: s-md(12);
        line-height: 150%;
      }
    }
    &__desc {
      max-width: s-md(305);
      margin-top: s-md(-19);
      font-size: s-md(14);
      line-height: 140%;
      letter-spacing: 0.045em;
    }
    &__btn {
      position: absolute;
      bottom: 0;
      right: s-md(16);
      transition-duration: 0.4s;
      transition-delay: 0s;
    }
    &__slider {
      &-wrapper {
        gap: initial;
      }
    }
    &__slide {
      &:first-child {
        order: -2;
      }
      &:nth-child(2) {
        order: -3;
      }
      &:last-child {
        order: -1;
      }
      &:first-child .hero__progress--desc {
        max-width: s-md(85);
      }
      &:nth-child(2) .hero__progress--desc {
        max-width: s-md(87);
      }
      &:nth-child(3) .hero__progress--desc {
        max-width: s-md(120);
      }
      &:nth-child(4) .hero__progress--desc {
        max-width: s-md(114);
      }
      &:first-child .hero__progress--count {
        margin-right: 0;
      }
    }
    &__progress {
      flex-direction: column;
      &--count {
        margin-right: 0;
        font-size: s-md(43);
        line-height: s-md(47);
      }
      &--desc {
        text-align: center;
        padding-top: 0;
        font-size: s-md(12);
        line-height: 140%;
      }
    }
  }
}
