.about {
  padding-bottom: s(199);
  &__container {
    display: flex;
    gap: s(58);
  }
  &__img {
    position: relative;
    top: s(-2);
    @include img(s(378));
    &-box {
      @include flex-50-x();
      align-items: flex-end;
      @include block(s(654), s(405));
      min-width: s(654);
      background: linear-gradient(var(--grey-shadow), var(--grey-shadow)) padding-box,
        linear-gradient(180deg, #8c2a27b1 0%, #e5554f 100%, #e5554f 100%) border-box;
      border-radius: s(10);
      border: s(2) solid transparent;
    }
  }
  &__content {
    margin-top: s(-4);
  }
  &__title {
    display: inline-flex;
    flex-direction: column;
    text-transform: uppercase;
    margin-bottom: s(51);
    & span {
      display: inline-block;
    }
    &--top {
      margin-bottom: s(21);
      font-family: 'GraphikLCG';
      font-weight: 300;
      font-size: s(16);
      line-height: 110%;
      letter-spacing: 0.015em;
    }
    &--bottom {
      @include text-bg('w');
      font-size: s(26);
      letter-spacing: 0.04em;
    }
  }
  &__desc {
    width: 100%;
    max-width: s(945);
    margin-bottom: s(39);
    font-family: 'GraphikLCG';
    font-weight: 300;
    font-size: s(20);
    line-height: 129%;
    letter-spacing: 0.015em;
    & strong {
      font-weight: 600;
    }
  }
  &__link {
    padding: s(14) s(28);
    @include transitions((background-color, border-color));
    &:hover,
    &:active {
      border-color: var(--red);
      background-color: var(--red);
    }
  }
  @include mobile {
    padding-bottom: s-md(119.5);
    &__container {
      flex-direction: column;
      gap: s-md(22);
    }
    &__img {
      top: 0;
      @include block(s-md(279), s-md(260));
      &-box {
        @include block(100%, s-md(175));
        min-width: initial;
        border-radius: s-md(10);
        border-width: s-md(2);
      }
    }
    &__title {
      margin-bottom: s-md(24.5);
      &--top {
        margin-bottom: s-md(10);
        font-size: s-md(12);
        line-height: s-md(12);
        letter-spacing: 0.05em;
      }
      &--bottom {
        font-size: s-md(20);
        line-height: 160%;
      }
    }
    &__desc {
      margin-bottom: s-md(28);
      font-size: s-md(18);
      line-height: 128%;
      letter-spacing: 0.035em;
      & strong {
        font-weight: 300;
      }
      & span {
        display: inline-block;
        &:first-of-type {
          max-width: s-md(300);
        }
        &:nth-of-type(2) {
          max-width: s-md(340);
        }
      }
    }
    &__link {
      padding: s-md(15.5) s-md(22.5);
    }
  }
}
