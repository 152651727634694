@mixin tablet {
  @media (max-width: 75em) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 48em) {
    @content;
  }
}
