// Перерасчёт из px в rem на ширине экрана 1920px
@function s($px) {
  @return calc($px / 10) + rem;
}
// Перерасчёт из px в rem на ширине экрана 768px
@function s-md($px) {
  @return calc(($px / 10) * 2) + rem;
}

//                                                            Подключение mixins
@import 'mixin';
//                                                            Подключение основных переменных
@import 'static/variables';
//                                                            Подключение шрифтов
@import 'static/fonts';

// Основной шрифт
$font-family: 'EuropeExt';
$fonts-1: 'regular' 400, 'bold' 700;
$fonts-2: 'light' 300, 'regular' 400, 'semibold' 600, 'bold' 700;

@each $name, $weight in $fonts-1 {
  @include font($font-family + '-' + $name, $font-family, $weight, 'normal', 'otf', 'opentype');
}
@each $name, $weight in $fonts-2 {
  @include font('GraphikLCG-' + $name, 'GraphikLCG', $weight);
}

html,
body {
  margin: 0 auto;
  padding: 0;
  font-family: $font-family, sans-serif; // шрифт по умолчанию
  font-weight: 700; // жирность шрифта по умолчанию
  font-style: normal;
  line-height: 155%;
}

html {
  font-size: 0.438335vw;
  @include mobile {
    font-size: 5px;
    font-size: 1.5625vw;
    font-size: calc(100 / $breakpoint-mobile) * 5vw; // где $breakpoint-mobile - это ширина мобильной версии в px
    -webkit-text-size-adjust: none;
  }
}

body {
  font-size: s(16); // размер шрифта по умолчанию (16px)
  color: var(--white); // цвет текста по умолчанию
  background-color: var(--grey-shadow); // заливка по умолчанию
  @include mobile {
    font-size: s-md(14); // размер шрифта по умолчанию (16px)
    line-height: 140%;
    -webkit-text-size-adjust: none;
  }
}

//                                                            Подключение сброса стандартных стилей
@import 'static/reset';
//                                                            Подключение кастомных стилей к элементам
@import 'static/custom-element';
//                                                            Подключение стилей к Yandex картам
@import 'static/yandex-map';

.container {
  width: s(1683);
  margin: 0 auto;
  @include mobile {
    width: 100%;
    padding: 0 s-md(16);
  }
}

.desktop {
  @include mobile {
    display: none;
  }
}

.mobile {
  display: none;
  @include mobile {
    display: initial;
  }
}

@import './header';
@import './footer';
