.footer {
  &__box {
    &--top {
      padding: s(61) 0 s(62);
      border-bottom: s(1) solid rgba(255, 255, 255, 0.6);
    }
    &--bottom {
      padding: s(31) 0;
      & .footer__container {
        justify-content: space-between;
      }
    }
  }
  &__container {
    display: flex;
  }
  &__logo {
    @include block(s(160), s(52));
    margin-right: s(110);
    & svg {
      @include block(inherit);
    }
  }
  &__content {
    @include flex-50-y();
    flex-direction: column;
    padding-top: s(12);
  }
  &__nav-list,
  &__contact-list {
    display: flex;
    gap: s(40);
  }
  &__nav {
    padding-bottom: s(59);
    &-link {
      text-transform: uppercase;
      letter-spacing: 0.04em;
    }
  }
  &__contact {
    &-item {
      &:nth-child(2) {
        & .footer__contact-desc {
          letter-spacing: 0.04em;
        }
      }
    }
    &-desc {
      font-style: normal;
      letter-spacing: 0.012em;
    }
  }
  &__copyright,
  &__desc,
  &__development,
  &__contact-desc {
    font-family: 'GraphikLCG';
  }
  &__contact-desc,
  &__copyright,
  &__desc {
    font-size: s(20);
    line-height: 130%;
    font-weight: 400;
  }
  &__desc {
    padding-left: s(53);
    color: rgba(255, 255, 255, 0.6);
  }
  &__development {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-right: s(61);
    &::after {
      position: absolute;
      content: '';
      top: s(-10);
      right: 0;
      @include block(s(50));
      background-image: url("data:image/svg+xml,%3Csvg width='92' height='88' viewBox='0 0 92 88' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_772_80)'%3E%3Crect x='21' y='42.5342' width='35.3553' height='35.3553' rx='3' transform='rotate(-45 21 42.5342)' fill='white'/%3E%3C/g%3E%3Cellipse cx='46.056' cy='42.5346' rx='14.3177' ry='14.3178' fill='%23232325'/%3E%3Crect x='37.1064' y='33.585' width='18.0242' height='18.0243' rx='3' fill='white'/%3E%3Cellipse cx='46.0312' cy='42.5104' rx='6.23918' ry='6.23887' fill='%23232325'/%3E%3Crect width='4.04418' height='4.04418' rx='1' transform='matrix(0.707081 -0.707132 0.707081 0.707132 43.3721 42.5342)' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_772_80' x='0.242615' y='0.776855' width='91.5148' height='91.5146' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeMorphology radius='2' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_772_80'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.279167 0 0 0 0 0.279167 0 0 0 0 0.279167 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_772_80'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_772_80' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 185%;
    }
    &--top {
      text-align: right;
      margin-top: s(-7);
      margin-bottom: s(5);
      font-family: 'Graphik LCG';
      font-size: s(17.5);
      font-weight: 300;
      line-height: s(17.5);
      color: rgba(255, 255, 255, 0.4);
    }
    &--bottom {
      font-size: s(18);
      font-weight: 400;
      line-height: s(18);
      & span {
        font-weight: 700;
      }
    }
  }
  @include mobile {
    &__box {
      &--top {
        padding: s-md(120) 0 0;
        border-bottom: none;
      }
      &--bottom {
        padding: s-md(24) 0 s-md(25);
        & .footer__container {
          align-items: flex-start;
          justify-content: initial;
        }
      }
    }
    &__container {
      flex-direction: column;
    }
    &__logo {
      @include block(s-md(101), s-md(32.7));
      margin-right: 0;
      margin-bottom: s-md(21.5);
    }
    &__content {
      align-items: flex-start;
    }
    &__nav-list,
    &__contact-list {
      flex-direction: column;
    }
    &__nav {
      padding-bottom: s-md(36);
      &-list {
        gap: s-md(14.75);
      }
      &-item {
        width: 100%;
        max-width: s-md(330);
        &:first-child {
          order: -2;
        }
        &:nth-child(3) {
          order: -1;
        }
      }
      &-link {
        font-size: s-md(14);
        line-height: 130%;
      }
    }
    &__contact {
      &-list {
        gap: s-md(16);
        padding-bottom: s-md(40);
        border-bottom: s-md(1) solid rgba(255, 255, 255, 0.6);
      }
      &-item {
        &:nth-child(2) {
          & .footer__contact-desc {
            letter-spacing: 0.07em;
          }
        }
      }
      &-desc {
        width: 100%;
        max-width: s-md(342);
        font-size: s-md(18);
        line-height: 129%;
        letter-spacing: 0.02em;
      }
    }
    &__copyright,
    &__desc {
      font-size: s-md(18);
      line-height: 130%;
      letter-spacing: 0.02em;
    }
    &__copyright {
      margin-bottom: s-md(23);
    }
    &__desc {
      margin-bottom: s-md(31.5);
      padding-left: 0;
    }
    &__development {
      padding-right: 0;
      padding-left: s-md(60);
      &::after {
        position: absolute;
        content: '';
        top: s-md(-7.5);
        right: initial;
        left: 0;
        @include block(s-md(52.5));
        background-size: 175%;
      }
      &--top {
        text-align: left;
        margin-bottom: s-md(7.5);
        font-size: s-md(15);
        line-height: s-md(15);
        letter-spacing: 0.085em;
      }
      &--bottom {
        font-size: s-md(18);
        line-height: s-md(18);
      }
    }
  }
}
