.founder {
  padding-top: s(310);
  &__container {
    display: flex;
    gap: s(40);
  }
  &__img {
    position: relative;
    top: s(-2);
    @include img(100%);
    &-box {
      @include flex-50-x();
      align-items: flex-end;
      @include block(s(678), s(521));
      min-width: s(678);
      background: linear-gradient(var(--grey-shadow), var(--grey-shadow)) padding-box,
        linear-gradient(180deg, #8c2a27b1 0%, #e5554f 100%, #e5554f 100%) border-box;
      border-radius: s(10);
      border: s(3) solid transparent;
    }
  }
  &__content {
    margin-top: s(-2);
  }
  &__title,
  &__name {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 160%;
    @include text-bg('w');
    letter-spacing: 0.04em;
  }
  &__title {
    margin-bottom: s(33);
    font-size: s(26);
  }
  &__desc {
    width: 100%;
    max-width: s(965);
    margin-bottom: s(40);
    font-family: 'GraphikLCG';
    font-weight: 300;
    font-size: s(22);
    line-height: 131%;
    letter-spacing: 0.015em;
    & strong {
      font-weight: 600;
    }
  }
  &__box {
    @include flex-50-y();
    justify-content: space-between;
  }
  &__name {
    font-size: s(18);
  }
  &__signature {
    @include img(s(260), s(89));
  }
  @include mobile {
    padding-top: s-md(213);
    &__container {
      flex-direction: column;
      gap: s-md(24);
    }
    &__img {
      top: s-md(27);
      &-box {
        border-radius: s-md(10);
        border-width: s-md(2);
        @include block(100%, s-md(199));
        & > picture {
          overflow: hidden;
          border-radius: s-md(10);
        }
      }
    }
    &__title {
      margin-bottom: s-md(13);
      font-size: s-md(20);
    }
    &__desc {
      max-width: initial;
      font-size: s-md(18);
      line-height: 128%;
      letter-spacing: 0.034em;
    }
    &__name {
      font-size: s-md(14);
      line-height: 150%;
      letter-spacing: 0;
    }
    &__signature {
      @include block(s-md(143), s-md(48));
    }
  }
}
